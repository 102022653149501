import React, { useState, useContext, useEffect } from 'react'
import '../../styles/Admin/users.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faDownLeftAndUpRightToCenter, faPlus, faPlusCircle, faRotateRight, faSearch, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons'
import { states } from '../../data/userstates';
import { ShareContext } from '../../context/ShareContext';
import Loading from '../../components/Loading'
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired'


function Users() {
  const { adminToken, isSidebarClosed } = useContext(ShareContext);

  const [isOpen, setIsOpen] = useState(false)
  const [expandForm, setExpandForm] = useState(false)
  const [search, setSearch] = useState('');
  const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [isLoading, setIsLoading]= useState(false);

  const [users, setUsers] = useState([]);
  const [filterSearch, setFilterSearch] = useState([]);
  const [parkinglots,setParkinglot] =useState([]);
  const [accesslevels,setAccesslevels]=useState([]);
  const [user, setUser]=useState({});
  const [isExpired, setIsExpired] = useState(false);
  const [isSaved, setIsSaved] =useState(false);

  const data = {
    "user": {
      "userid": "",
      "userpwd": "",
      "firstname": "",
      "lastname": "",
      "middlename": null,
      "emailaddress": "",
      "preferredphone": "",
      "alternatephone": "",
      "extension": "",
      "address1": "",
      "address2": null,
      "city": null,
      "state": "",
      "zip": "",
      "userstatus": "",
      "ispwdreset": "",
      "accesslevel": "",
      "authenquestion1": null,
      "authenanswer1": "",
      "authenquestion2": null,
      "authenanswer2": "",
      "createdate": "",
      "updatedate": ""
    }, 
    "parkinglot":""
  }

  const [createData, setCreateData] = useState(data)
  const [openedEdit, setOpenedEdit] = useState(false)
  const [editData, setEditData] = useState()

  const [loading, setLoading] = useState(false)

  // console.log(users);

  const reserve = (id) => {
    const idData = users?.find(item => item.userid === id)

    return idData

  }

  const onSearch=(ee)=>{
    if(ee!=''){
    const _filterSearch = users?.filter(item => {
          const searchValue = item.firstname.toLowerCase().includes(ee.toLowerCase()) || item.emailaddress.toLowerCase().includes(ee.toLowerCase()) || item.lastname.toLowerCase().includes(ee.toLowerCase())
      
          return searchValue;
        })
    setFilterSearch(_filterSearch);
    }else{
      setFilterSearch(users);
    }
    
    setSearch(ee);
  }

  // console.log(tableState);

  const editTheRow = (row) => {
  
    setIsNew(false);
    setIsOpen(true);
   // const u = reserve(id);
   console.log(row);
    setUser(row)
  }

  const onNewUser=()=>{
    setIsNew(true);
    setUser(data);
    setIsOpen(true)
  }
  // console.log(editData);

  const createChange = (e) => {
    const { name, value } = e.target;

    setCreateData((prevData) => ({
      ...prevData,
      user: {
        ...prevData.user,
        [name]: value,
      },
      [name]: value
    }));
  }

  const editChange = (e) => {
    setUser({...user, [e.target.name]: e.target.value})
  }

  const onSave = async(e) => {
    e.preventDefault();
    setLoading(true)
    var rdata={user,parkinglot:user.parkinglotid}
    const url= isNew ?`${Constants.apiUrl}/parkingadmin/user`:`${Constants.apiUrl}/parkingadmin/user/${user.userid}`;
console.log(JSON.stringify(rdata));
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': adminToken
        },
        body: JSON.stringify(rdata)
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 0) {
          setLoading(false)
          setIsOpen(false);
          setIsNew(false);
          alert('Congratulations, User account successfully '+(isNew?'created!':'updated!'))
          //update the row
          if(isNew){
            setUsers([...users,user]);
            setFilterSearch([...users,user]);
            setSearch('');
          }else{
            //update the item
            const updatedArray = users?.filter(obj => obj.userid !== user.userid)
            
            setUsers([...updatedArray,user]);
            setFilterSearch([...updatedArray,user]);
            setSearch('');

          }
        }else if (data.code==-10){
          setIsExpired(true);
      }else{
          alert(data.status)
      }
      }).catch((error) => {
        console.log(error);
        setLoading(false)
      })

    // console.log(createData);

  }


  const exitForm = () => {
    setIsOpen(false)
 
  }

  const searchChange = (e) => {
    onSearch(e.target.value)
  } 

  const openDeleteBox = (id) => {
    setIsDialogBoxOpen(true);

    const data = reserve(id)

    setEditData(data)
}

const closeDialogBox = () => {
  setIsDialogBoxOpen(false);
}

const deleteIt = async(id) => {
  const updatedArray = users?.filter(obj => obj.userid !== id)

  const updatesearch = filterSearch?.filter(obj => obj.userid !== id)

  setUsers(updatedArray)
  setIsDialogBoxOpen(false)

  fetch(`${Constants.apiUrl}/parkingadmin/user/${editData.userid}/remove`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': adminToken,
      body:''
    },
   
  })
  .then((resp) => resp.json)
  .then((data) => {
    console.log(data);
    setUsers(updatedArray)
    setFilterSearch(updatesearch);
  })

}

useEffect(() => {
  setLoading(true)
  fetch(`${Constants.apiUrl}/parkingadmin/users`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'Authorization': adminToken,
    }
  })
  .then((resp) => resp.json())
  .then((usersData) =>  {
    setUsers(usersData.ulist)
    setFilterSearch(usersData.ulist);
    setParkinglot(usersData.parkinglots);
    setAccesslevels(usersData.accesslevels)
    setLoading(false)
  })
  .catch((error) => {
    console.log(error);
    setLoading(false)
  })
}, [adminToken, isNew])

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
          <Head />
          { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}
          <div className="users">
            <div className="head">
              <h1>Users</h1>
              <p>Users connecting to admin section</p>
            </div>
            <div className="body-wrapper">
              <div className="head-block">
                <h3>Admin Users</h3>
                <div className="head-icons">
                  <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                </div>
              </div>
              <div className="adding-users">
                <button onClick={() =>onNewUser() }>Add New User <FontAwesomeIcon icon={faPlus} /></button>
                <div className="search-input">
                  <FontAwesomeIcon icon={faSearch} />
                  <input type="text" placeholder='Search User (name, email)' value={search} onChange={searchChange} />
                </div>
              </div>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>E-mail</th>
                      <th>Phone Number</th>
                      <th>Parking Lot</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filterSearch?.map((row, idx) => { return (
                        <tr key={idx}>
                          <td>{row.firstname}</td>
                          <td>{row.lastname}</td>
                          <td>{row.emailaddress}</td>
                          <td>{row.preferredphone}</td>
                          <td className='address-bar'>{row.parkinglotname}</td>
                          <td style={{width:'100px'}}>
                            <ul>
                              <li onClick={() => editTheRow(row)}>Edit</li>
                              
                              <li className='delete' onClick={() => openDeleteBox(row.userid)}>Delete</li>
                            </ul>
                          </td>
                        </tr>
                      )})
                    }
                  </tbody>
                </table>
             {loading && <div className={   "congrats show"}>
                    <Loading message={'Loading Users'}/>
                </div>}   
              </div>
            </div>
          </div>
          <div className={ isOpen ? "create-wrapper active" : "create-wrapper"}>
            <div className="close-it" onClick={exitForm}>
              <FontAwesomeIcon icon={faClose} />  
            </div>
            <div className={expandForm ? "create-account expanded": "create-account"}>
              <div className="create-header">
                <h3>{!isNew ? 'Update Record' :'Create User Account'}</h3>
                <FontAwesomeIcon icon={expandForm ?faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className='close-create' onClick={() => setExpandForm(!expandForm)}/>
              </div>
              {
                isOpen && 
                <form method="post" className="create" onSubmit={onSave}>
                <div className="input">
                  <label htmlFor="user-id">User ID</label>
                  <input type="text" id='user-id' name='userid' value={user.userid} onChange={editChange}  disabled={!isNew}/>
                </div>
                <div className="inputs">
                  <div className="input">
                    <label htmlFor="user-firstname">First Name</label>
                    <input type="text" id='user-firstname' name='firstname' value={user.firstname} onChange={editChange} required />
                  </div>
                  <div className="input">
                    <label htmlFor="user-lastname">Last Name</label>
                    <input type="text" id='user-lastname' name='lastname' value={user.lastname} onChange={editChange} required />
                  </div>
                </div>
                <div className="inputs">
                  <div className="input">
                    <label htmlFor="user-email">Email</label>
                    <input type="email" id='user-email' name='emailaddress' value={user.emailaddress} onChange={editChange} required/>
                  </div>
                 {isNew && <div className="input">
                    <label htmlFor="user-password">Password</label>
                    <input type="password" id='user-password' name='userpwd' value={user.userpwd} onChange={editChange} required ={isNew}/>
                  </div>} 
                </div>
                  
                <div className="inputs">
                  <div className="input">
                    <label htmlFor="user-phone">Phone</label>
                    <input type="tel" id='user-phone' name='preferredphone' value={user.preferredphone} onChange={editChange} required />
                  </div>
                  <div className="input">
                    <label htmlFor="user-altphone">Alt. Phone</label>
                    <input type="tel" id='user-altphone' name='alternatephone' value={user.alternatephone} onChange={editChange} />
                  </div>
                  <div className="input">
                    <label htmlFor="user-extension">Extension</label>
                    <input type="tel" id='user-extension' name='extension' value={user.extension} onChange={editChange} />
                  </div>
                </div>
                <div className="input">
                    <label htmlFor="user-address1">Address 1</label>
                    <input type="text" id='user-address1' name='address1' value={user.address1} onChange={editChange} required />
                </div>
                <div className="input">
                    <label htmlFor="user-address2">Address 2</label>
                    <input type="text" id='user-address2' name='address2' value={user.address2} onChange={editChange} />
                </div>
                <div className="inputs">
                  <div className="input">
                      <label htmlFor="city">City</label>
                      <input type="text" id='city' name='city' value={user.city} onChange={editChange} required />
                  </div>
                  <div className="input">
                    <label htmlFor="user-state">State</label>
                    <select name="state" id="user-state" value={user.state} onChange={editChange} required>
                      <option value="" disabled>Select State/Province</option>
                      {
                      states?.length>0 &&  states.map((state, id) => {
                          return (
                            <option key={id} value={state.code}>{state.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
                  
                <div className="inputs">
                  <div className="input">
                    <label htmlFor="user-accesslevels">Access Levels</label>
                    <select name="accesslevel" id="user-accesslevels" value={user.accesslevel} onChange={editChange} required>
                      <option value="" disabled></option>
                      {
                       accesslevels?.length>0 &&  accesslevels.map((p, id) => {
                          return (
                            <option key={id} value={p}>{p}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className="input">
                      <label htmlFor="user-parkinglot">Parking Lot</label>
                      <select id='user-parkinglot' name='parkinglot' value={user.parkinglotid} onChange={editChange}>
                      <option value="" >All Parkinglot</option>
                      {
                     parkinglots?.length>0 &&    parkinglots.map((p, id) => {
                          return (
                            <option key={id} value={p.parkinglotid}>{p.parkinglotname}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="input">
                    <label htmlFor="user-status">Status</label>
                    <select name="userstatus" id="user-status" value={user.userstatus} onChange={editChange}>
                      <option value=""></option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                </div>
                <div className="btns">
                  <button type='submit'>Save <FontAwesomeIcon icon={faPlusCircle} /></button>
                  <p onClick={exitForm} className='goBack'>Back to users</p>
                </div>
              </form> 
              }
            </div>
          </div>
       {isDialogBoxOpen && <div className={  "delete-wrapper show" }>
            <div className="dialog">
              <div className="head">
                <h4>Delete User</h4>
                <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
              </div>
              <div className="main">
                <h3>Are you sure you want to delete user: { editData?.firstname + ' ' + editData?.lastname }?</h3>
                <p>This action will permanently delete this User. Please click 'Delete User' to continue or Cancel to abort!</p>
                <div className="btns">
                  <button onClick={() => deleteIt(editData.userid)}>Delete User</button>
                  <p onClick={() => closeDialogBox()}>Cancel</p>
                </div>
                </div>
            </div>
          </div> }   
        </div>
    </div>
  )
}

export default Users