import './App.css';

import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { ShareContext } from './context/ShareContext';
import * as Constants from './constants';
import Header from './components/User/Header';
import Home from './pages/User/Home';
import Footer from './components/User/Footer';
import Reservation from './pages/User/Reservation';
import Directions from './pages/User/Directions';
import Login from './pages/User/Login';
import Signup from './pages/User/Signup';
import TermsofUse from './pages/User/TermsofUse';
import PrivacyPolicy from './pages/User/PrivacyPolicy';
import Contact from './pages/User/Contact';
import Faq from './pages/User/Faq';
import Articles from './pages/User/Articles';
import ArticlePage from './pages/User/ArticlePage';
import Airports from './pages/User/Airports';
import ModifyReservations from './pages/User/ModifyReservations';
import UpcomingReservation from './pages/User/UpcomingReservation';
import Admin from './pages/Admin/Admin';
import Users from './pages/Admin/Users';
import SearchResult from './pages/User/SearchResult';
import SearchResultId from './pages/User/SearchResultId';
import Checkout from './pages/User/Checkout';
import ManageClient from './pages/Admin/ManageClient';
import ParkingLot from './pages/Admin/ParkingLot';
import Reservations from './pages/Admin/Reservations';
import ManageAirport from './pages/Admin/ManageAirport';
import Corporate from './pages/Admin/Corporate';
import TravelAgency from './pages/Admin/TravelAgency';
import ManageDiscount from './pages/Admin/ManageDiscount';
import Reports from './pages/Admin/Reports';
import LotPerformance from './pages/Admin/LotPerformance';
import ForgotPassword from './pages/User/ForgotPassword';
import Profile from './pages/Admin/Profile';
import Modify from './pages/Admin/Modify';
import SubmittedTickets from './pages/Admin/SubmittedTickets';
import InternalReport from './pages/Admin/InternalReport';
import ExternalReport from './pages/Admin/ExternalReport';
import HotelReport from './pages/Admin/HotelReport';
import ArrivalDeparture from './pages/Admin/ArrivalDeparture';
import OtherTaxes from './pages/Admin/OtherTaxes';
import CommisionReport from './pages/Admin/CommisionReport';
import ReservationRefund from './pages/Admin/ReservationRefund';
import InventoryReport from './pages/Admin/InventoryReport';
import UserProfile from './pages/User/UserProfile';
import Receipt from './pages/User/Receipt';
import Receipttag from './pages/User/Receipttag';
import AdminCheckout from './pages/Admin/Checkout';
import AdminSearchResult from './pages/Admin/SearchResult';
import AdminFullReceipt from './pages/Admin/AdminFullReceipt';
import PrintTag from './pages/Admin/PrintTag';
import Refund from './pages/Admin/Refund';
import ReservationReport from './pages/Admin/ReservationReport';
import CancelReservation from './pages/User/CancelReservation';
import SingleParkinglot from './pages/Admin/singleparkinglot';


const TRACKING_ID = "UA-10593943-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);



function App() {
  
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false)

  const [userData, setUserData] = useState({})
  const [airportList, setAirportList] = useState([])
  const [searchData, setSearchData] = useState()
  const [receiptDetails, setReceiptDetails] = useState({})
  const [reservations, setReservations] = useState([]);
  const [adminData, setAdminData] = useState({});
  const [reservationId, setReservationId]= useState(0);
  const [reportForm, setReportForm] = useState({});

  // console.log(isAdminLoggedIn);

  const [formData, setFormData] = useState({
    "airportName": "",
    "airportCode": "",
    "startDate": "",
    "startTime": "12:00",
    "endDate": "",
    "endTime": '12:00',
    "startdatetime":"",
    "enddatetime":"",
    "carCount": "1",
    "discountcode":"",
    "selectedlot":"",
    "corporatecode":"", 
   
  })

  const airportPicked = airportList?.find(obj => obj.airportcode === formData.airportCode);

  formData.startdatetime = formData.startDate + ' ' + formData.startTime;
  formData.enddatetime = formData.endDate + ' ' + formData.endTime;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [token, setToken] = useState('')
  const [adminToken, setAdminToken] = useState('')

  const [customerDetails, setCustomerDetails] = useState(userData.c);

  // console.log(userData);

  const [adminReport, setAdminReport] = useState([]);
  const [loadReport, setLoadReport] = useState()

  const [isSidebarClosed, setIsSidebarClosed] = useState(false)

  const [adminUserDetails, setAdminUserDetails] = useState(adminData.user)

  const airports = `${Constants.apiUrl}/reservation/airports/active`;

  useEffect(() => {
    fetch(airports, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setAirportList(data);
      })
      .catch(error => console.error(error))
  }, [])

  // console.log(airportList);

  useEffect(() => {
      if (airportPicked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          airportCode: airportPicked.airportcode,
          airportName: airportPicked.airportlongname
        }));
      } else {
        // Handle the case where no matching airport is found
        console.warn("No matching airport found for:", formData.airportName);
      }
    // Attempt to retrieve the token from localStorage
    const storedToken = localStorage.getItem('accessToken');
    const customer = localStorage.getItem('customerDetails');
    const parsedCustomer = customer ? JSON.parse(customer) : null;
  
    // Check if both a token and customer exist
    if (storedToken && customer) {
      // If a token exists and is different, set the token in the state and update isUserLoggedIn
      if (storedToken !== token) {
        setToken(storedToken);
        setIsUserLoggedIn(true);
        setCustomerDetails(parsedCustomer);

      }
    } else {
      // If any of the conditions is not met, set isUserLoggedIn to false
      setIsUserLoggedIn(false);
    }

  }, [token, airportPicked, formData.airportCode]);

  useEffect(() => {
    const storedToken = localStorage.getItem('adminToken');
    const adminUser = localStorage.getItem('adminDetails');
  
    if (storedToken) {
      setAdminToken(storedToken);
  
      if (adminUser) {
        try {
          const parsedadminUser = JSON.parse(adminUser);
  
          setAdminUserDetails(parsedadminUser);
          setIsAdminLoggedIn(true);
        } catch (error) {
          console.error('Failed to parse adminDetails from localStorage', error);
          setIsAdminLoggedIn(false);
        }
      } else {
        setIsAdminLoggedIn(false);
      }
    } else {
      setIsAdminLoggedIn(false);
    }
  }, [adminToken, setIsAdminLoggedIn, setAdminUserDetails]);

  useEffect(() => {
    const handleLinkClick = (event) => {
      // Check if the clicked element is a link
      if (event.target.tagName.toLowerCase() === 'a') {
        // Scroll to the top of the page
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };

    // Attach the click event listener to the document
    document.addEventListener('click', handleLinkClick);

    // Remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, []); 
  
  return (
    <div className="App">
      <ShareContext.Provider value={{isUserLoggedIn, setIsUserLoggedIn, formData, setFormData, isFormSubmitted, setIsFormSubmitted, userData, setUserData, token, setToken, 
        customerDetails,setCustomerDetails, airportList, searchData, setSearchData, receiptDetails, setReceiptDetails, reservations, setReservations,
         isAdminLoggedIn, setIsAdminLoggedIn, adminData, setAdminData, adminUserDetails, setAdminUserDetails, adminToken, setAdminToken,
         reservationId, setReservationId, adminReport, setAdminReport, loadReport, setLoadReport, isSidebarClosed, setIsSidebarClosed, reportForm, setReportForm}}>
        <Router>
          <Header />
          <Routes>
            <Route path='/'  exact element={<Home />} />
            <Route path='/reservation' exact element={<Reservation />} />
            <Route path='/reservation/search' exact element={<SearchResult />} />
            <Route path='/reservation/search/:reservationId' exact element={<SearchResultId />} />
            <Route path='/reservation/checkout/:selectedlotId' exact element={<Checkout />} />
            <Route path='/reservation/checkout/:selectedlotId/:reservationId' exact element={<Checkout />} />
           
            <Route path='/reservation/modify/:reservationId' exact element={<ModifyReservations />} />
            <Route path='/reservation/cancel/:reservationId' exact element={<CancelReservation />} />
            <Route path='/reservation/upcoming-reservations' exact element={<UpcomingReservation />} />
            <Route path='/reservation/airports/:airport' exact element={<Airports />} />
            <Route path='/reservation/directions' exact element={<Directions />} />
            <Route path='/user-profile' exact element={<UserProfile />} />
            <Route path='/reservation/login' exact element={<Login />} />
            <Route path='/home/register' exact element={<Signup />} />
            <Route path='/home/forgetpassword' exact element={<ForgotPassword />} />
            <Route path='/home/termofservice' exact element={<TermsofUse />} />
            <Route path='/home/privacy' exact element={<PrivacyPolicy />} />
            <Route path='/home/contact' exact element={<Contact />} />
            <Route path='/reservation/full-receipt/:reservationId' exact element={<Receipt />} />
            <Route path='/reservation/receipt-tag/:reservationId' exact element={<Receipttag />} />
            <Route path='/home/faq' exact element={<Faq />} />
            <Route path='/articles' exact element={<Articles />} />
            <Route path='/articles/:articleId/:itemId' exact element={<ArticlePage />} />
            
            <Route path='/admin' exact element={<Admin />} />
            <Route path='/admin/login' exact element={<Admin />} />
            <Route path='/admin/:username/:password' exact element={<Admin />} />

            <Route path='/admin/users' exact element={<Users />} />
            <Route path='/admin/manage-client' exact element={<ManageClient />} />
            <Route path='/admin/parking-lot' exact element={<ParkingLot />} />
            <Route path='/admin/reservations' exact element={<Reservations />} />
            <Route path='/admin/manage-airport' exact element={<ManageAirport />} />
            <Route path='/admin/corporate' exact element={<Corporate />} />
            <Route path='/admin/travel-agency' exact element={<TravelAgency />} />
            <Route path='/admin/manage-discount' exact element={<ManageDiscount />} />
            <Route path='/admin/reports' exact element={<Reports />} />
            <Route path='/admin/reports/submitted-tickets' exact element={<SubmittedTickets />} />
            <Route path='/admin/reports/reservations' exact element={<ReservationReport />} />

            <Route path='/admin/reports/reservations-internal-report' exact element={<InternalReport />} />
            <Route path='/admin/reports/reservations-external-report' exact element={<ExternalReport />} /> 
            <Route path='/admin/reports/hotel-report' exact element={<HotelReport />} />
            <Route path='/admin/reports/arrival-departure-report' exact element={<ArrivalDeparture />} />
            <Route path='/admin/reports/other-taxes-report' exact element={<OtherTaxes />} />
            <Route path='/admin/reports/commission-report' exact element={<CommisionReport />} />
            <Route path='/admin/reports/reservation-refund-report' exact element={<ReservationRefund />} />
            <Route path='/admin/reports/inventory-report' exact element={<InventoryReport />} />
            <Route path='/admin/lot-performance' exact element={<LotPerformance />} />
            <Route path='/admin/profile' exact element={<Profile />} />
            <Route path='/admin/modify/:reservationId' exact element={<Modify />} />
            <Route path='/admin/full-receipt/:reservationId' exact element={<AdminFullReceipt />} />
            <Route path='/admin/tag/:reservationId' exact element={<PrintTag />} />
            <Route path='/admin/refund/:reservationId' exact element={<Refund />} />
            <Route path='/admin/checkout/:selectedlotId/:reservationId' exact element={<AdminCheckout />} />
            <Route path='/admin/search' exact element={<AdminSearchResult />} />
            <Route path='/admin/search/:reservationId' exact element={<AdminSearchResult />} />
            <Route path='/admin/parkinglot/:parkinglotid' exact element={<SingleParkinglot />} />
            <Route path='/admin/parkinglot' exact element={<SingleParkinglot />} />
          </Routes>
          <Footer />
        </Router>
      </ShareContext.Provider>
     
    </div>
  );
}

export default App;
