import React, { useContext, useState, useRef,useEffect } from 'react'
import '../../styles/Admin/travelagency.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faRotateRight, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter} from '@fortawesome/free-solid-svg-icons';
import { states } from '../../data/userstates'
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading'
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired'

function TravelAgency() {
    const { adminToken,isSidebarClosed } = useContext(ShareContext);

    const [openCreate, setOpenCreate] = useState(false);
    const [agencyOpen, setAgencyOpen] = useState(false);
    const [expandForm, setExpandForm] = useState(false);
    const [openedEdit, setOpenedEdit] = useState(false);
    const [createAgency, setCreateAgency] = useState('table');
    const [agencies, setAgencies] = useState([]);
    const [filterData, setFilterData]=useState([]);
    const [isLoading, setIsLoading]= useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [editComm, setEditComm] = useState({
        commission: '',
        parkinglotid: '',
        agencyid: '',
        recordid: ''
    })

    const formData = {
        agencyId: '',
        agencyName: '',
        contactName: '',
        email: '',
        phone: '',
        ext: '',
        updateDate: '',
        address: '',
        addressTwo: '',
        city: '',
        state: '',
        zip: '',
        accStatus: '',
        commissions: [
            {
                commission: '',
                parkingLot: ''
            }
        ],
}
    const [agencyForm, setAgencyForm] = useState(formData)
    const [editData, setEditData] = useState(formData)
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false)

    const formChange = (e) => {
        setAgencyForm({...agencyForm, [e.target.name]: e.target.value})
    }

    const changeEdit = (e) => {
        setEditData({...editData, [e.target.name]: e.target.value})
    }

    const closePopUp = () => {
        setOpenCreate(false) 
        setOpenedEdit(false)

        setAgencyForm(formData)
    }

    const retrieve = (id) => {
        console.log(`agencyid ${id}`)
        const theData = filterData.find(item =>  item.agencyid === id);
        // console.log(theData);
        return theData;
    }

    const editClick = (id) => {
       // const idData = retrieve(id);
       
        setOpenCreate(true);
        setOpenedEdit(true)
       // setEditData(idData)
       onLoadAgency(id);
    }

    const openAgency = (id) => {
        setAgencyOpen(true);

       // const idData = retrieve(id);

        setCreateAgency('table')

        onLoadAgency(id);
    }

    const closeAgencyBox = () => {
        setCreateAgency(false);
        setAgencyOpen(false)
    }

    const editAgencyCommission = (id) => {
        setCreateAgency('edit')
        
        var commId = editData.commissions.find(item => item.agencyid === id)

        // console.log(commId);

        setEditComm(commId)
    }

    const deleteCommission = (id) => {
        const newObj = {...editData}

        newObj.commissions.splice(id, 1)
        
        setEditData(newObj)
    }
    
    const openDeleteBox = (id) => {
        setIsDialogBoxOpen(true);
    
        const data = retrieve(id)
    
        setEditData(data)
    }
     
      const closeDialogBox = () => {
        setIsDialogBoxOpen(false);
      }
    
      const deleteIt = (id) => {
      /*   const updatedArray = filterData.filter(obj => obj.agencyId !== id);
      const updatedArray2 = agencies.filter(obj => obj.agencyId !== id);
        setFilterData(updatedArray)
        setAgencies(updatedArray2);
        setIsDialogBoxOpen(false) */
      }

      useEffect(() => {
        //Runs only on the first render
       // var sToken=localStorage.getItem("accessToken");
       onLoadAgencies();
      }, []);
      const onLoadAgencies=()=>{
        setIsLoading(true);
        fetch(`${Constants.apiUrl}/parkingadmin/travelagencies`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            setIsLoading(false);
            if(jresp.code==0){
                // console.log(jresp);
            setAgencies(jresp.agencies);
            setFilterData(jresp.agencies);
            }else if (jresp.code==-10){
                setIsExpired(true);
            }else{
                alert(jresp.status)
            }
            
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          })
      }

      const onLoadAgency=(id)=>{
        setIsLoading(true);
        fetch(`${Constants.apiUrl}/parkingadmin/travelagencies/${id}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            setIsLoading(false);
            if(jresp.code==0){
                // console.log(jresp);
            setEditData({...jresp.agency,commissions:jresp.comm})
            }else if (jresp.code==-10){
                setIsExpired(true);
            }else{
                alert(jresp.status)
            }
            
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          })
      }
     
    //   console.log(editData.commissions);

      const onSave = async(e) => {
        e.preventDefault();
 
        const action= openedEdit ?'update':'new';
        const rdata={...editData, action};
        console.log(JSON.stringify(rdata));
          fetch(`${Constants.apiUrl}/parkingadmin/travelagency`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': adminToken
            },
            body: JSON.stringify(rdata)
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.code === 0) {
                setAgencyOpen(false);
              alert('Congratulations, Agency account successfully '+(!openedEdit?'created!':'updated!'))
            }else{
                alert(data.status);
            }
          })
    
      }
      const onNew=()=>{
        setOpenCreate(true);
        setOpenedEdit(false);
        setEditData({
            "agencyid":"",
            "agencyname":"",
            "contactname":"",
            "contactphone":"",
            "extension":"",
            "email":"",
            "address1":"",
            "address2":"",
            "city":"",
            "state":"",
            "zipcode":"",
            "createdate":"",
            "updatedate":"",
            "createdby":"",
            "updatedby":"",
            "agencystatus":"",
            "action":"",
            "commissions":[]
        });
      }

      const newComm = () => {
        setCreateAgency('create')
        setEditComm({})
      }

      const changeComm = (e) => {
        setEditComm({...editComm, [e.target.name]: e.target.value})
      }


  return (
    <div className='admin-body'>
        <Sidebar />
        { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="travel-agency">
                <div className="head">
                    <h1>Agencies</h1>
                    <p>Create / Modify Agencies</p>
                </div>
                <div className= "body-wrapper">
                    <div className="head-block">
                        <h3>Agencies</h3>
                        <div className="head-icons">
                            <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                        </div>
                    </div>
                    <div className="adding-client">
                        <button onClick={() => onNew()}>Create New Account</button>
                    </div>
                    <div className={ openCreate ? "table-wrapper hide" :"table-wrapper"}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Agency ID</th>
                                    <th>Agency Name</th>
                                    <th>Contact Name</th>
                                    <th>E-mail</th>
                                    <th>Phone</th>
                                    <th>Ext</th>
                                    <th>Update Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterData.length>0 && filterData.map((agency, i) => { return (
                                    <tr key={i}>
                                        <td>{agency.agencyid}</td>
                                        <td>{agency.agencyname}</td>
                                        <td>{agency.contactname}</td>
                                        <td>{agency.email}</td>
                                        <td>{agency.contactphone}</td>
                                        <td>{agency.extension}</td>
                                        <td>{Constants.formatDate(agency.updatedate) }</td>
                                        <td>
                                            <ul>
                                                <li onClick={() => editClick(agency.agencyid)}>Edit</li>
                                                <li onClick={() => openAgency(agency.agencyid)}>Agency Commision</li>
                                                <li className='delete' onClick={() => openDeleteBox(agency.agencyid)}>Delete</li>
                                            </ul>
                                        </td>
                                    </tr>
                                )}) }
                            </tbody>
                        </table>
                        <div className={ isLoading ? "congrats show" : "congrats"}>
                            <Loading message={'Loading Agencies'}/>
                        </div>
                    </div>
                    <div className={ openCreate ? "create-wrapper active" : "create-wrapper"}>
                        <div className="close-it" onClick={() => closePopUp()}>
                            <FontAwesomeIcon icon={faClose} />  
                        </div>
                        <div className={expandForm ? "create-account expanded": "create-account"}>
                            <div className="create-header">
                                <h3>{ openedEdit ? 'Edit Travel Agency' : 'New Travel Agency'}</h3>
                                <FontAwesomeIcon icon={expandForm ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} 
                                className='close-create' onClick={() => setExpandForm(!expandForm)}/>
                            </div>
    
                            <form method="post" onSubmit={onSave}>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="agencyId">Travel Agency ID</label>
                                        <input type="text" id='agencyId' name='agencyid' value={editData.agencyid} onChange={changeEdit} />
                                    </div>
                                    <div className="input">
                                        <label htmlFor="agencyName">Agency Name</label>
                                        <input type="text" id='agencyName' name='agencyname' value={editData.agencyname} onChange={changeEdit} />
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="contactName">contact Person Name</label>
                                        <input type="text" id='contactName' name='contactname' value={editData.contactname} onChange={changeEdit} />
                                    </div>
                                    <div className="input">
                                        <label htmlFor="phone">Contact Phone</label>
                                        <input type="tel" id='phone' name='contactphone' value={editData.contactphone} onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="ext">Contact Extension</label>
                                        <input type="tel" id='ext' name='extension' value={editData.extension} onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="email">Contact E-mail Address</label>
                                        <input type="email" id='email' name='email' value={editData.email} onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="address">Address</label>
                                    <input type="text" id='address' name='address1' value={editData.address1} onChange={changeEdit}/>
                                </div>
                                <div className="input">
                                    <label htmlFor="addressTwo">Address 2</label>
                                    <input type="text" id='address2' name='address2' value={editData.address2} onChange={changeEdit}/>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="city">City</label>
                                        <input type="text" id='city' name='city' value={editData.city} onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="state">State</label>
                                        <select name="state" id="state" value={editData.state} onChange={changeEdit}>
                                            <option value="" disabled selected>Select State/Providence</option>
                                            { states.map((state, id) => <option key={id} value={state.code}>{state.name}</option>) }
                                        </select>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="zip">ZIP Code</label>
                                        <input type="text" id='zip' name='zip' value={editData.zipcode} onChange={changeEdit} />
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="accStatus">Account Status</label>
                                    <select name="accStatus" id="accStatus" value={editData.agencystatus} onChange={changeEdit}>
                                        <option value="" disabled selected></option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <div className="btns">
                                    <button type='submit'>Save Travel Agency</button>
                                    <p className="goBack" onClick={() =>  closePopUp()}>Back to Agencies</p>
                                </div>
                            </form> 
                            
                        </div>
                    </div>
                    <div className={ agencyOpen ? "agency-commission active" : "agency-commission"}>
                        <div className="real-form">
                            <div className="create-header">
                                <h3>Agency Commission</h3>
                                <div className="btns">
                                    <button onClick={() => newComm()}>Create Agency Commission</button>
                                    <FontAwesomeIcon icon= {faClose} className='close-create' onClick={closeAgencyBox}/>   
                                </div>
                            </div>
                            {  createAgency === 'table' && <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Commission</th>
                                            <th>Parking Lot</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                          editData.commissions?.length>0 &&  editData.commissions.map((item, id) => { return(
                                            <tr key={id}>
                                                <td>{item.commission}%</td>
                                                <td>{item.parkinglotid}</td>
                                                <td>
                                                    <ul>
                                                        <li onClick={() => editAgencyCommission(item.agencyid)}>Edit</li>
                                                        <li className='delete' onClick={() => deleteCommission(id)}>Delete</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            )})
                                        }
                                    </tbody>
                                </table>
                            </div>}
                            { (createAgency === 'edit' || createAgency === 'create') && <div className="create-agency">
                                <h3>{createAgency === 'edit' ? 'Edit' : createAgency === 'create' ? 'Create' : ''} Agency Commission</h3>
                                <div className="input">
                                    <label htmlFor="commission">Commission(%)</label>
                                    <input type="number" id='commission' name='commission' value={editComm.commission} onChange={changeComm}/>
                                    <p>Value should be between 0 and 100 (e.g., entering 10 means 10% of the total price will be the agency's commission)</p>
                                </div>
                                <div className="input">
                                    <label htmlFor="commissionLot">Parking Lot</label>
                                    <select name="parkingLot" id="commmission" value={editComm.parkinglotid} onChange={changeComm}>
                                        <option value="All">All</option>
                                    </select>
                                </div>
                                <div className="btn-back">
                                    <button type='submit'>Save Commission</button>
                                    <p onClick={() => setCreateAgency('table')}>Back to List</p>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={ isDialogBoxOpen ? "delete-wrapper show" : "delete-wrapper"}>
                <div className="dialog">
                    <div className="head">
                        <h4>Delete Agency</h4>
                        <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                    </div>
                <div className="main">
                    <h3>Are you sure you want to delete Agency: { editData?.agencyname }?</h3>
                    <p>This action will permanently delete this Agency. Please click 'Delete Agency' to continue or Cancel to abort!</p>
                    <div className="btns">
                        <button onClick={() => deleteIt(editData?.agencyid)}>Delete Agency</button>
                        <p onClick={() => closeDialogBox()}>Cancel</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default TravelAgency