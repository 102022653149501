import React from 'react'
import TopBar from '../../components/User/TopBar'
import '../../styles/User/termsofuse.css'
import ReactGA from 'react-ga';

function TermsofUse() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className='terms-of-use'>
        <TopBar />
        <div className="main-text">
            <h1>AirportParkingInc.com Terms of Use</h1>
            <p className="note">
            This page describes the terms and conditions applicable to your use of the services offered through this website. Read this document carefully! If you are unable or unwilling to abide by the terms and conditions stated in this document, DO NOT USE this website or any of the services provided herein. By using this website and its services, you unconditionally agree to be bound by the terms and conditions set forth herein.</p>

            <p className="term">
                <span>NAMES</span> <br />
                The owner/operator of this website is referred to in these Terms of Use as the Webmaster. The owner and the operator of the parking facility where you elect to book a reservation are referred to in this Terms of Use collectively as the Operator.
            </p>
            
            <p className="term">
                <span>PARKING & OTHER CHARGES</span> <br />
                The advertised rates on the website at any given time are the then-applicable parking charges only for the parking facility you choose. The final amount billed to you may include a service fee to the Webmaster plus applicable taxes and credit/debit card transaction fees. You agree to pay all such charges and fees. Please also keep in mind that each parking facility and each state may charge different rates and fees.
            </p>

            <p className="term">
                <span>WHEN YOU PAY</span> <br />
                Each lot is handled differently. Most lots require you to pay your entire reservation costs in full at the time of booking. However, some may require you to pay for one day only, plus the reservation fee with the remaining balance being due at the time you arrive at the parking facility. Payment is made on our website using a credit card at the time of booking. Credit card processing will occur in real-time and any card processing fees will be added to the quoted rate that you must pay.
            </p>

            <p className="term">
                <span>BOOKING CONFIRMATION NOTICE</span> <br />
                Immediately upon purchasing your reservation, you will be emailed a booking confirmation. Please print your booking confirmation and present it to the designated representative at the parking facility upon your arrival. Some parking facilities may you require you to fill in the make and model of your vehicle and the license plate number and issuing state on the booking confirmation.
            </p>

            <p className="term">
                <span>WHERE THE PARKING FACILITIES ARE LOCATED</span> <br />
                All parking facilities are located within close proximity to the airport. Directions will be contained in the booking confirmation that is emailed to you immediately upon booking your reservation.
            </p>

            <p className="term">
                <span>ACCESS TO PARKING FACILITIES</span> <br />
                All parking facilities will generally be accessible 24 hours a day, 365 days per year. However, you will only be able to check-in and have use of the shuttle during each parking facilities particular hours of operation. Hours of Operation for lot accessibility and shuttle accessibility vary per parking facility. Parking facilities will not accept your vehicle if it is a commercial, recreational or other oversized vehicle that cannot fit into a single parking stall. All of the parking facilities are "park at your own risk". Neither Webmaster nor Operator will be responsible for your vehicle or any items left inside your vehicle.
            </p>

            <p className="term">
                <span>TRANSPORTATION TO THE AIRPORT</span> <br />
                Transportation to and from the airport is provided by each parking facility, free of charge. Shuttle hours of operation and frequency vary from facility to facility. Please allow at least 30 minutes of additional time when arriving at the parking facility to facilitate getting to the airport on time. Most airports recommend arriving at your terminal at least 2 hours earlier than your scheduled flight. Please know that you can check in, in advance, by calling the facilities front desk if you wish to use the parking facility, but not the shuttle service.
            </p>

            <p className="term">
                <span>CHANGING YOUR RESERVATION</span> <br />
                Your charges reflect the arrival and departure dates indicated when you made your reservation. If your reservation should change, such changes may be subject to parking availability and additional charges may apply. If you need to make any changes, you will need to contact us.
            </p>

            <p className="term">
                <span>MAKING A RESERVATION FOR SOMEONE ELSE</span> <br />
                The Webmaster does allow you to make a reservation for someone other than yourself. You will only need to put the name of the person parking the car on the reservation form where it says "Person Parking Car" on the payment page.
            </p>

            <p className="term">
                <span>PARKING SPACE SUBSTITUTIONS</span> <br />
                On rare occasions, a parking facility may have to change a parking option. If an upgrade occurs, then you will not be assessed any additional charges. If a service reduction occurs (such as changes in parking from indoor to outdoor, covered to uncovered, etc.), then rates will be adjusted according to the level of parking service provided.
            </p>

            <p className="term">
                <span>OPERATOR'S RIGHT TO REMOVE YOUR VEHICLE FROM THE PARKING FACILITY</span> <br />
                You agree that Operator has the right, at your sole cost and expense, to have your vehicle removed from the Parking Facility in the event you leave your vehicle in the parking facility for more than ten (10) days beyond the final date of your reservation as booked by you with the Webmaster. In such event, your vehicle will be moved to the property of the nearest available vehicle towing company. You agree that you are solely responsible for any and all towing and storage fees that are charged by the towing company, in addition to the Additional Fees you owe the Operator.
            </p>

            <p className="term">
                <span>CANCELLATION POLICY</span> <br />
                Reservations must be cancelled at least 48 hours prior to the date and time you are scheduled to arrive at the parking facility. If you cancel less than 48 hours from your reserved time at the parking facility, you will forfeit all monies paid. If you make a reservation within 48 hours of your arrival date and time at the parking facility and cancel the reservation, you will forfeit all monies paid. If you cancel more than 48 hours prior to your reserved time at the parking facility, a full refund, less the Webmaster's service fee(s) will be credited back to your credit or debit card within seventy-two (72) hours thereafter. The Webmaster's services may include, but are not limited to, the $5.00 Customer Service fee and any Credit/Debit Card Transaction fees that may have been paid at the time you paid for your reservation. <br />

                If you wish to cancel a reservation, you could do any of the following: <br />

                Logon to your Airport Parking Inc account. Locate the specific reservation and click the cancel button located to the right of it. You will be required to enter the last four digits of the credit card you used to make the reservation. <br />
                Call our customer service number at 865-366-7014 and provide our customer service representative with your transaction ID and request a cancellation. <br />
                Take the following steps to e-mail your cancellation request: <br />
                <ul>
                    <li> Step 1: Open a copy of your e-mail confirmation.</li>
                    <li> Step 2: Remove the information in the Subject Line, and then type in "Cancel".</li>
                    <li>  Step 3: Forward (not "reply") a copy of your modified e-mail confirmation to Services@AirportParkingInc.com</li>
                </ul>
            </p>

            <p className="term">
                <span>VOLUNTARY ASSUMPTION OF RISK</span> <br />
                This website functions as an information source to connect travelers in need of airport or cruise port parking with privately owned parking facilities. Webmaster requires the parking facility operators to make every good faith effort to honor your reservation. In the event of unforeseen situations, including but not limited to, Acts of God, War, or other conditions such as, severe weather conditions, your reservation could be cancelled. Although Webmaster attempts to affiliate with only reputable, licensed and insured companies, Webmaster has no control over the quality or safety of any parking facility. <br />
                You freely, knowingly, and voluntarily assume and are solely responsible for all risks of any kind associated with:
                <ul>
                    <li>(i) using this website</li>
                    <li>(ii) dealing with the Operator</li>
                    <li> (iii) dealing with any other third parties with whom you may come into contact with as a result of or incident to your use of this website</li>
                    <li>(iv) being on the Operator's property</li>
                    <li>(v) parking at the parking facility and</li>
                    <li> (vi) using any shuttle service. You also park at your own risk and are solely responsible for your vehicle and any items left inside your vehicle.</li>
                </ul>
                Although the Webmaster makes every effort to provide accurate and clear information on the website, the material on this website may contain inaccuracies or typographical errors. Neither the Webmaster nor the Operator makes any representations about the accuracy, completeness, reliability, or timeliness of the website or the information provide therein. Changes are made to this website periodically and may be made at any time without notice. Your use of this website and the information posted on this website and the services provided through this website are at your own risk.
            </p>

            <p className="term">
                <span>DISCLAIMER OF ALL WARRANTIES</span> <br />
                This website and the information posted on this website are provided on an "as is" basis without any express or implied warranties of any kind. To the fullest extent permitted by law, the Webmaster disclaims all warranties, whether expressed or implied, including but not limited to the warranty of non-infringement, fitness for a particular use or purpose and merchantability. The Webmaster makes no warranties about the accuracy, completeness, reliability, or timeliness of the information, services, software, text, graphics, links, or transactions with Operators. <br />
                The Webmaster does not warrant that this website will operate without error. The Webmaster does not warrant that the website or its server is free of computer viruses or any other harmful mechanisms. If your use of this website or the mechanisms within this website results in the need for servicing or replacing equipment or data, the Webmaster is not responsible for such actions or any resulting expenses, such losses, costs and/or expenses being subject to the indemnity provisions set forth above.
            </p>

            <p className="term">
                <span>DISCLAIMER OF ACTUAL, INCIDENTAL, CONSEQUENTIAL, AND OTHER DAMAGES</span> <br />
                
                In no event shall the Webmaster, the Operator, or any third parties mentioned on this website be liable for any damages whatsoever including, but not limited to, incidental damages, consequential damages, loss of profits, loss of income, damages resulting from lost data, business interruption damages, or any other kinds of damage resulting from or incident to the use or inability to use this website and/or any information posted on this website, whether based on warranty, contract, tort, or any other legal provision or principle.
            </p>

            <p className="term">
                <span>INDEMNITY IN FAVOR OF WEBMASTER AND OPERATOR</span> <br />
                You agree to protect, defend, indemnify and hold the Webmaster and the Operator, and their respective directors, officers, employees, agents and representatives completely harmless from and against any and all liabilities, losses, suits, claims, judgments, fines or demands of any kind arising by reason of injury to or death of any person, or damage to any property, including reasonable attorney's fees, court costs, expenses, and expert witness fees, of any nature whatsoever arising out of or incident to or in any manner associated with:
                <ul>
                    <li>(i) any reservations made hereunder</li>
                    <li>(ii) any occurrences or incidents at or related to the parking facilityr</li>
                    <li>(iii) the use or occupancy of the parking facility</li>
                    <li>(iv) any occurrences or incidents in, on or related to the use of any shuttle service and</li>
                    <li> (v) the use or occupancy of any shuttle; regardless of where the injury, death, or damage may occur.</li>
                </ul>
                You further agree to protect, defend, indemnify hold the Webmaster and the Operator, and their respective directors, officers, employees, agents and representatives completely harmless from and against any and all travel-related costs, expenses, losses, claims, fines, or demands of any kind associated with missed flights, rescheduling flights, lodging, meals, transportation, inconvenience, mental anguish, or other direct or indirect costs, losses or expenses in any manner associated with or incident to: 
                <ul>
                    <li>(i) any reservations made hereunder</li>
                    <li>(ii) any occurrences or incidents at or related to the parking facility</li>
                    <li>(iii) the use or occupancy of the parking facility</li>
                    <li>(iv) any occurrences or incidents in, on or related to the use of any shuttle service</li>
                    <li>(v) the use or occupancy of any shuttle and</li>
                    <li>(vi) any occurrences or incidents in, on or related to the storage of your vehicle in any municipal garage to which your car was relocated; regardless of when or where the cost, lost or expense may occur.</li>
                </ul>
            </p>

            <p className="term">
                <span>COPYRIGHTS AND TRADEMARKS</span> <br />
                Airport Parking of Miami Inc. DBA Airport Parking Inc is a trade name. All rights are reserved. Their graphics, logos, trade names, and other published material may not be used without their prior written consent. Intellectual property owned, controlled, acquired, stored, distributed or licensed by them or by third-parties who have licensed the same to them are protected by U.S. and International copyright laws.
            </p>

            <p className="term">
                <span>WEBSITE USE</span> <br />
                Users are strictly prohibited from violating or attempting to violate the security of this website, including, but not limited to:
                <ul>
                    <li>(i) accessing data not intended for such user</li>
                    <li>(ii) attempting to interfere with service to any user, host or network</li>
                    <li>(iii) submitting a virus to this website</li>
                    <li>(iv) overloading, flooding, spamming, or crashing</li>
                    <li>(v) attempting to access other parties accounts without authorization</li>
                    <li>(vi) attempting to probe, scan or test the vulnerability of a system or network</li>
                    <li>(vii) attempting to breach security or authentication measures without prior authorization or</li>
                    <li>(viii) attempting to access, alter, corrupt or delete programming. Any of the foregoing, as well as any other violations of this website or any part or system thereof, may result in civil and/or criminal action.</li>
                </ul>
            </p>

            <p className="term">
                <span>CONCLUSIVE EVIDENCE OF YOUR ACCEPTANCE</span> <br />
                You making a reservation on this website to park at operator's parking facility shall be conclusive evidence, as against you, that you understand and accept all of these Terms of Use and agree to be fully bound hereby.
            </p>

            <p className="term">
                <span>SEVERABILITY</span> <br />
                The provisions of these Terms of Use are intended to be severable. If for any reason any provision of these Terms of Use shall be held invalid or unenforceable in whole or in part in any jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the extent such part is invalid or unenforceable, the remainder of these Terms of Use to remain in full force and effect. However, if any provision is unenforceable or invalid in one particular jurisdiction, such unenforceability or invalidity will not affect in any manner the validity or enforceability thereof in any other jurisdiction or the remaining provisions hereof in any jurisdiction.
            </p>

            <p className="term">
                <span>HEADINGS FOR CONVENIENCE</span> <br />
                The captions to the paragraphs or subparagraphs of these Terms of Use and the title of the exhibit itself are solely for the convenience of the parties, are not a part of the Terms of Use, and shall not be used for the interpretation of, or determination of the validity of these Terms of Use or any provision hereof.
            </p>

            <p className="term">
                <span>JURISDICTION AND VENUE</span> <br />
                You understand and agree that any and all disputes between you, the Webmaster, and/or the Operator arising out of or associated with:
                <ul>
                    <li>(i) any reservations made hereunder</li>
                    <li>(ii) any occurrences or incidents at or related to the parking facility</li>
                    <li>(iii) the use or occupancy of the parking facility</li>
                    <li>(iv) any occurrences or incidents in, on or related to the use of any shuttle and</li>
                    <li>(v) the use or occupancy of any shuttle shall be subject to jurisdiction only in the state in which the parking facility is located, and all parties freely and voluntarily consent to the jurisdiction of the state and federal courts of such state, and only those courts, for all such disputes. You, the Webmaster and the Operator further understand and agree that the venue for any such dispute shall be proper only in the county or district in which the parking facility is located, and each party freely and voluntarily consents to venue in that county, and only in that county, for all such disputes.</li>
                </ul>
            </p>

            <p className="term">
                <span>WAIVER OF RIGHT TO JURY TRIAL</span> <br />
                You, the Webmaster and the Operator expressly, freely, knowingly and voluntarily waive their respective rights to trial by jury of any action or proceeding between or among them or their successors arising out of or relating to these Terms of Use.
            </p>

            <p className="term">
                <span>ACKNOWLEDGEMENT</span> <br />
                These Terms of Use, including all documents and information referenced herein, represent the entire understanding and agreement among you, the Webmaster and the Operator, and supersede any other offers, promises, representations or statements not contained herein. You agree to be bound by these Terms of Use by your use of this website. You acknowledge that these Terms of Use are subject to change without notice to or prior consent from you; however, any such changes after you make a reservation shall not be effective as to that particular reservation.
            </p>
        </div>
    </div>
  )
}

export default TermsofUse