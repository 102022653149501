import React, { useState, useContext } from 'react';
import '../../styles/User/header.css'
import Logo from '../../assets/logo.png';
import { ShareContext } from '../../context/ShareContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faBars, faLocationDot, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Header() {
    const [airportMenuClicked, setAirportMenuClicked] = useState(false);
    const [openMenu, setOpenMenu] = useState(false)

    const { airportList,isUserLoggedIn } = useContext(ShareContext)

    const path = useLocation();

        // Check if the current route contains "/admin"
    const isAdminRoute = path.pathname.includes('/admin');

  return isAdminRoute ? null : (
    <header>
        <Link to='/'>
            <img src={Logo} alt='Airport Parking Inc Logo' />
        </Link>
        <nav className={openMenu ? 'user-nav open' : 'user-nav'}>
            <ul>
                <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/' className={ path.pathname === '/' ? 'nav-link show' : 'nav-link'}>
                        Home
                        <div className='line'></div>
                    </Link>
                </li>
                <li className={ airportMenuClicked ? 'airports clicked' : 'airports'} onClick={() => {setAirportMenuClicked(!airportMenuClicked)}}>
                    <div className='dropdown'>
                        <p>Airports</p>
                        <FontAwesomeIcon icon={ airportMenuClicked ? faAngleUp : faAngleDown} />
                    </div>
                    <div className='line'></div>
                    <div className='all-airports'>
                        <ul className='airport-list'>
                            {
                                airportList.map((airport, id) => {
                                    return(
                                        <li key={id} onClick={() =>  setOpenMenu(false)}>
                                            <Link to={`/reservation/airports/${airport.airportcode}-${airport.airportcity}-airport`} className='airport-name'>
                                                <FontAwesomeIcon icon={faLocationDot} />
                                                {airport.airportlongname}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </li>
                <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/reservation' className={ path.pathname === '/reservation' ? 'nav-link show' : 'nav-link'}>
                        Reservations
                        <div className='line'></div>
                    </Link>
                </li>
                <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/reservation/directions' className={ path.pathname === '/reservation/directions' ? 'nav-link show' : 'nav-link'}>
                        Directions
                        <div className='line'></div>
                    </Link>
                </li>
              {! isUserLoggedIn && <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/reservation/login' className={ (path.pathname === '/reservation/login') || (path.pathname === '/home/register') ? 'nav-link show' : 'nav-link'}>
                        <button>My Accounts</button>
                    </Link>
                </li>}  
            </ul>
        </nav>
        <FontAwesomeIcon icon={openMenu ? faXmark : faBars} className='hamburger' onClick={() => { setOpenMenu(!openMenu)}} />
    </header>
  )
}

export default Header