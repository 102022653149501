import React, { useContext, useState} from 'react'
import { ShareContext } from '../../context/ShareContext';
import '../../styles/User/airportform.css'
import { timeArray } from '../../data/timeArray';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import LoadingImg from '../../assets/loading.gif';
import * as Constants from '../../constants';
import moment from "moment";

function AirportForm( props) {
    const { formData, setFormData, setIsFormSubmitted, airportList, setSearchData, setReservationId} = useContext(ShareContext)
    const [loading, setLoading]=useState(false);
    const {reservationId} =props;
    const fetchurl=reservationId==null || reservationId==0?  `${Constants.apiUrl}/reservation/search`: `${Constants.apiUrl}/reservation/${reservationId}/search`;
    const navigateTo=reservationId==null || reservationId==0?'/reservation/search': `/reservation/search/${reservationId}`;
    // console.log('fetch search url '+fetchurl);
    const currentDate =moment().add(-1,'d').format('YYYY-MM-DD');// new Date().toISOString().split('T')[0];

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value});
    };

    const dummySubmit = async(e) => {
        setLoading(true);
        e.preventDefault();
        console.log(JSON.stringify(formData));
        try {
            const resp = await fetch(fetchurl, {
              method: 'POST',
              body: JSON.stringify(formData),
              headers: {
                'Content-Type': 'application/json',
              },
            });
            
            const responseData = await resp.json();
            setSearchData(responseData)
             console.log(responseData)
            if (responseData.code === 0) {
                setSearchData(responseData)
                navigate(navigateTo)
                setIsFormSubmitted(true)
            }else {
              console.log(responseData.status);
            }
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        
    }

  return (
    <div className="search-airport">
        <h2>AirportParkingInc.com is focused on saving you time and money on your airport parking.</h2>
        <p className='note'>Note: Customers can choose any time they want even if the shuttle service does not run during those times</p>
        <form method='post' onSubmit={dummySubmit}>
            <div className="inputs">
                <select name="airportCode" className='airports' value={formData.airportCode} onChange={handleChange} required>
                    <option disabled value=''> Airport or Seaport</option>
                    {
                        airportList.map((airport, idx) => {
                            return (
                                <option key={idx} value={airport.airportcode}>{airport.airportlongname}</option>
                            )
                        })
                    }
                </select>
                <div className='each'>
                    <div className="input">
                        <div className="label">
                            <p>Arrival Date</p>
                        </div>
                        <input type="date" name="startDate" id="arrival-date" value={formData.startDate} onChange={handleChange} min={currentDate} required/>
                    </div>
                    <select name="startTime" value={formData.startTime}  onChange={handleChange} className="time arrival-time" required>
                        <option disabled>Arrival Time</option>
                            {
                                timeArray.map((time, id) => {
                                    return(
                                        <option key={id} value={time.value}>{time.time}</option>
                                    )
                                })
                            }
                    </select>
                </div> 
                <div className='each'>
                    <div className="input">
                        <div className="label">
                            <p>Departure Date</p>
                        </div>
                        <input type="date" name="endDate" id="departure-date" value={formData.endDate} onChange={handleChange} required min={formData.startDate}/>
                    </div>
                    <select name="endTime"  value={formData.endTime} className="time departure-time" onChange={handleChange}  required>
                            <option disabled>Departure Time</option>
                            {
                                timeArray.map((time, index) => {
                                    return(
                                        <option key={index} value={time.value}>{time.time}</option>
                                    )
                                })
                            }
                    </select>
                </div>
            </div>
            <div style={{flexDirection:'row'}}>
                <button type="submit" style={{ display:'inline'}}> Search <FontAwesomeIcon icon={faSearch} /></button>
          {/*  {reservationId>0 && <button type="button" style={{ display:'inline'}} onClick={()=>setReservationId(0)}> Clear Modification {reservationId} </button>}  */}
            </div>
            
            {loading && <img src={LoadingImg} height={50} alt='Sponsor Logo' />}            
    </form>
    </div>
    
  )
}

export default AirportForm