import React, { useState, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import '../../styles/User/airports.css'
import TopBar from '../../components/User/TopBar'
import AirportForm from '../../components/User/AirportForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { ShareContext } from '../../context/ShareContext';
import { Link } from 'react-router-dom'
import LoadingImg from '../../assets/loading.gif';
import * as Constants from '../../constants';
import ReactGA from 'react-ga';

function Airports() {

    const airportName = useParams();

    var airportcode=airportName.airport.split('-')[0]; // to remove the first guy


    const { airportList, searchData, isFormSubmitted, setIsFormSubmitted, setFormData, setSearchData, formData } = useContext(ShareContext)
    const airportData = airportList.filter(airport => airport.airportcode === airportcode)[0]
    const [rawContent,setRawContent] =useState('');
    const [loading, setLoading]=useState(false);

    Constants.seo({title: airportData?.airportlongname,
    metaDescription:'Airport Parking Reservations. Quick And Easy Long Term Offsite Airport Parking Reservations. 24-hour Video Surveillance. Convenient Shuttle Service.'})

    const currentDate = useMemo(() => {
        return new Date();
    }, []) 

    // Add a week (7 days) to the current date
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(currentDate.getDate() + 7);

    // Format the next week date as a string in 'YYYY-MM-DD' format
    const formattedNextWeek = nextWeek.toISOString().split('T')[0];

    const data = useMemo(() => {
        return {
            "airportName": airportData?.airportcity,
            "airportCode": airportData?.airportcode,
            "startDate": currentDate.toISOString().split('T')[0],
            "startTime": "12:00PM",
            "endDate": formattedNextWeek,
            "endTime": '12:00PM',
            "startdatetime":"",
            "enddatetime":"",
            "carCount": "1",
            "discountcode":"",
            "selectedlot":"",
            "corporatecode":"",
        }
    } , [airportData, currentDate, formattedNextWeek]);

    data.startdatetime = data.startDate + ' ' + data.startTime;
    data.enddatetime = data.endDate + ' ' + data.endTime;

    const onOpenContent= async()=>{
        // console.log('about to make the raw content call');
        try {
            const resp = await fetch(`${Constants.apiUrl}/reservation/seocontent/${airportData?.airportcode}`, {
              method: 'GET',
              
              headers: {
                'Content-Type': 'application/json',
              },
            });
        
            const responseData = await resp.json();
            // console.log(responseData)
        
            if (responseData.code === 0) {
                setRawContent(responseData.content)
            } else {
              console.log(responseData.status);
            }
          } catch (error) {
            console.error(error);
          }
    }

    useEffect(() => {
        setIsFormSubmitted(true);
        setFormData(data);
        setLoading(true);

        const searchParking = async () => {
            try {

              await  onOpenContent();
              const resp = await fetch(`${Constants.apiUrl}/reservation/search`, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                  'Content-Type': 'application/json',
                },
              });
          
              const responseData = await resp.json();
              setSearchData(responseData);
          
              if (responseData.code === 0) {
                setIsFormSubmitted(true);
              } else {
                console.log(responseData.status);
              }
                setLoading(false);
            } catch (error) {
              console.error(error);
                setLoading(false);
            }
          };

          searchParking();
    }, [setIsFormSubmitted, data, formData, setFormData, setSearchData])

   
    const [answerClicked, setAnswerClicked] = useState(null)

    const handleAnswerClick = (id) => {
        setAnswerClicked( id === answerClicked ? null : id)
    }

    // console.log(rawContent);

    const whyData = [
        {
            serialNumber: 1,
            text: `Save 40-70% on Remarkably Cheap Airport Parking in ${airportData?.airportlongname}`
        },
        {
            serialNumber: 2,
            text: `You Can't Get Much Closer Parking to the ${airportData?.airportlongname} Airport - Just 1.8 Miles Away`
        },
        {
            serialNumber: 3,
            text: `We Offer 24/7 Complimentary ATL Shuttle Service from Select Parking Lots to the ${airportData?.airportlongname} Airport`
        },
        {
            serialNumber: 4,
            text: 'Our Long-Term Airport Parking is Very Secure, so You Can Have Peace of Mind that Nothing Will Happen to Your Vehicle While You Are Away'
        },
        {
            serialNumber: 5,
            text: `Your ${airportData?.airportlongname} Airport Parking Reservation is 100% Guaranteed`
        },
        {
            serialNumber: 6,
            text: `This Allows You to Make ${airportData?.airportlongname} Airport Parking Reservations on Your Timeline, from the Comfort of Your Home, Rather than Going Through the Hassle of Finding Parking Near the Airport the Day of Your Flight`
        },
        {
            serialNumber: 7,
            text: "We Are One of the Nation's Largest and Most Trusted Providers of Long-Term Airport Parking, with High Ratings from Our Customers"
        }
    ]

    const faqData = [
        {
            id: 1,
            question: 'How Does the Airport Parking Reservation Process Work?',
            answer: `Begin by selecting the day and times for your ${airportData?.airportlongname} airport parking reservation and click on the 'search' button. Be sure to allow time for our free ${airportData?.airportlongname} airport shuttle to get you back and forth to the airport. Select the specific ${airportData?.airportlongname} airport parking lot that you feel offers the best value by clicking the 'reserve' button. Enter all of reservation information, and select 'book reservation' at the bottom of the screen. After clicking 'book reservation,' please be patient. It may take a minute or two to process your transaction and lock in your cheap airport parking rates. Once complete, your reservation confirmation and receipt will appear on your screen. We also send a copy of your receipt to the email address you provided. Your receipt contains all the information you'll need to park, including: your airport of departure, your chosen parking lot name, address, phone and directions, your parking lot arrival and return dates and times, your name and the name of the person parking, your price and parking rate arrival and transportation information. You must present the parking lot with a copy of your receipt along with one form of personal identification to validate the offer and receive credit for your payment. Please note that the service fee is not refunded to you by the parking lot. If you do not present your receipt to the parking lot, you will not get credit for your online payment and this online payment will not be refunded at a later date. If you are unable to print your confirmation, please notify us (you can notify us by clicking here). We will arrange to either email a copy of your receipt to an alternate email address or fax or mail you a copy. To receive a copy by mail your departure date must be a minimum of 5 business days from the date your transaction was made.`
        },
        {
            id: 2,
            question: `How and When Do I Pay for My ${airportData?.airportlongname} Airport Parking Reservation?`,
            answer: "You pay the total amount quoted on our web site (the 'Pay Online' price) and the remainder, if any applies (the 'Due at Lot' price) to the parking lot. Our parking affiliates accept cash and all major credit cards. Many of our facilities require full payment at the time of booking. However, a couple of our facilities only want the equivalent of one day paid at time of booking with the remaining balance being due at the parking facility. Our website will state this in the pay online price area."
        },
        {
            id: 3,
            question: `Can I Make an ${airportData?.airportlongname} Airport Parking Reservation for Someone Else?`,
            answer: "Absolutely. Simply enter the person's name in the 'Person Parking' field when completing your online JFK airport parking reservation. Both your name and their name will appear on your receipt."
        },
        {
            id: 4,
            question: `How Do I Get to the Lot Where I Made My ${airportData?.airportlongname} Airport Parking Reservation?`,
            answer: "The address will be sent to your email as soon as your reservation is complete. Every address in our system can be readily found on Google Maps and most other mapping services. You can also click here for our maps https://www.airportparkinginc.com/reservation/directions"
        },
        {
            id: 5,
            question: `How does the ${airportData?.airportlongname} Shuttle Service Work?`,
            answer: `We provide free, 24/7 ${airportData?.airportlongname} airport shuttle service to and from our ${airportData?.airportlongname} parking lots. Please view the specific terms and conditions set forth for the ${airportData?.airportlongname} airport parking lot you have chosen. Also, please allow 10-15 minutes for check-in and transportation to your terminal. Upon your return to the ${airportData?.airportlongname} airport, there are courtesy phones in the baggage area, and there is a phone number on your claim check to call for pick-up. At larger airports, shuttle buses constantly circle the airport. At a few airports you will be taken to the airport in your own car and returned via shuttle bus.`
        },
        {
            id: 6,
            question: "What if My Flight is Delayed?",
            answer: `Essentially, you are charged from the time you arrive at the ${airportData?.airportlongname} airport parking lot until the time you leave the lot, not for the departure and arrival times on your airline ticket. If your flight is delayed in departing, you can simply delay your arrival. The bigger concern for most flyers is if their return flight is delays. Most generally, if you are still within a 24-hour period, and the delay was due to no fault on your part, we will not charge additional monies for your long-term airport parking in ${airportData?.airportlongname}. We understand that airport delays are possible, and want to become your preferred provider of ${airportData?.airportlongname} airport parking.`
        },
        {
            id: 7,
            question: "Do You Have a Group or Frequent Flyer Program?",
            answer: `We have a Corporate Program to save your firm even more on discount airport parking. We will be happy to work with you on custom corporate plans. Whether you are simply looking for discount ${airportData?.airportlongname} airport parking, or your company is looking for the cheapest airport parking across the country, please call 865-366-7014.`
        },
        {
            id: 8,
            question: "How Are You Able to Offer Such Cheap Airport Parking?",
            answer: "The short answer is volume. As one of the largest and most respected providers of discount airport parking in America, our overhead per booking is extraordinary low. We are also able to negotiate the best rates with hotels and off-site airport parking specialists to provide you with remarkably cheap airport parking."
        }
    ]

    ReactGA.pageview(window.location.pathname + window.location.search);
    
  return (
    <div className='airports-page'>
        <TopBar />
        <div className="full-body">
            <h1><span>Airport Parking:</span> {airportData?.airportlongname}</h1>
            <p className="address">{airportData?.airportaddress}</p>
            <AirportForm />
            <div className="available-reservations">
                <div className="title">
                    <h2>Reservations</h2>
                    <h3>Search Results for {airportData?.airportlongname} from  {data.startDate} to {data.endDate}</h3>
                </div>
                {loading && 
                    <div className="loads">
                        <img src={LoadingImg} height={50} alt='Sponsor Logo' />
                        <p>Loading Reservations for {airportData?.airportlongname}</p>
                    </div>
            }  
                { isFormSubmitted ? <div className="cards">
                    { searchData?.parkings?.map((item, idx) => {
                         const createMarkup = (content) => ({ __html: (content ?? '').replace(/<br\s*\/?>/g, '\n') });

                        return (
                            <div className="card" key={idx}>
                                <div className="left-note">
                                    <h3>{item.parkinglotname}</h3>
                                    {item.holidaydaysinavg.map((holiday, ix)=>{
                                       return (<p className="head-note" key={ix}>Please note: Holiday rate of ${holiday.dailyrate} per day in affect from {Constants.formatDate(holiday.startdate) } to {Constants.formatDate(holiday.enddate) }</p>)
                                    })} 
                                    <div className="general-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.shortsecuritystatement)} />
                                    </div>
                                    <div className="complementary-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.transportation)} />
                                    </div>
                                </div>
                                <div className="price">
                                <div className="top-price">
                                    <div className="ticketing">
                                        <h4>TYPE</h4>
                                        <p>{item.parkingtype}</p>
                                    </div>
                                    <div className="ticketing">
                                        <h4>AVG. RATE</h4>
                                        <p>${item.avgrate.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="mid-price">
                                    <p>Sub-Total = ${item.subtotal.toFixed(2)}</p>
                                    <p>Service Fee = ${item.servicefee.toFixed(2)}</p>
                                    <p>Sales Tax = ${item.tax.toFixed(2)}</p>
                                    {item.freedaysavings === 0 ? '' : <p>Free Day Savings = ${item.freedaysavings.toFixed(2)}</p> }
                                    <h3>Amount Total = ${item.totalamt.toFixed(2)}</h3>
                                </div>
                                <div className="total-price">
                                    <h3>Amount Total = ${item.totalamt.toFixed(2)}</h3>
                                    <p>Due at Lot = ${item.dueatlot.toFixed(2)}</p>
                                </div>
                                <div className="btn">
                                    <Link to={`/reservation/checkout/${item.parkinglotid}`}>
                                        <button disabled={item.booked === true || item.blocked === true}  className={item.booked === true || item.blocked === true ? 'cancel-btn' : 'reserve-btn'}>
                                            {item.booked === true || item.blocked === true ? 'UNAVAILABLE' : 'RESERVE'}
                                        </button>
                                    </Link>
                                </div>
                                </div>
                            </div>
                        )
                    })}
                </div> : ''}
            </div>
            <div className="why-this-airport">
                <h2>Why Choose Us for {airportData?.airportlongname} Airport Parking?</h2>
                <div className="the-whys">
                    {
                        whyData.map((why, idx) => {
                            return (
                                <div className="why" key={idx}>
                                    <p className='number'>{why.serialNumber}</p>
                                    <p>{why.text}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="airports-faq">
                <h2>FAQs About Long Term Airport Parking in {airportData?.airportlongname}</h2>
                <div className="questions">
                    {
                        faqData.map((faq, idx) => {
                            return (
                                <div className="question" key={idx} onClick={() => handleAnswerClick(faq.id)}>
                                    <div className="top">
                                        <h3>{faq.question}</h3>
                                        <FontAwesomeIcon icon={ answerClicked === faq.id ? faMinusCircle : faPlusCircle} className='icon' />
                                    </div>
                                    <div className={ answerClicked === faq.id ? "answer show" : "answer"}>
                                        <p>{faq.answer}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
          {/*   <div className="airports-faq"  dangerouslySetInnerHTML={{__html:  rawContent}}>
               
            </div> */}
        </div>
    </div>
  )
}

export default Airports