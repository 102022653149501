import React, { useState, useContext, useEffect  }  from 'react'
import '../../styles/Admin/modify.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-regular-svg-icons'

import { useParams } from 'react-router-dom'
import { timeArray } from '../../data/timeArray';
import { useNavigate } from 'react-router-dom'
import LoadingImg from '../../assets/loading.gif';
import { ShareContext } from '../../context/ShareContext';
import * as Constants from '../../constants';
import Loading from '../../components/Loading'
import Expired from '../../components/Expired'

function Modify() {
    const {reservationId} = useParams()
console.log('reservationId vslue: '+reservationId);
    const {isSidebarClosed,  setFormData, airportList, adminToken, setSearchData, setIsFormSubmitted} = useContext(ShareContext);
    const [item,setItem]=useState(null);
    const [loadingStatus, setLoadingStatus] =useState(true);
    const [numofdays, setNumofdays]=useState(0);
  const [isExpired, setIsExpired] = useState(false);

   // console.log('all caried forward reservations');
   // console.log(reservations);
  //  const item = reservations.find(reserve => reserve.transactionid === parseInt(transactionId));
     

    const navigate = useNavigate()

    const [updateReserve, setUpdateReserve] = useState({
        "airportName": '',
        "airportCode": "",
        "startDate": '',
        "startTime": '',
        "endDate": '',
        "endTime": '',
        "startdatetime":"",
        "enddatetime":"",
        "carCount": 1,
        "discountcode":"",
        "selectedlot":"",
        "corporatecode":"", 
    })

    const initValues=(resv)=>{
        setItem(resv);
        var fd={
            "airportName": resv.airport,
            "airportCode": resv.airportcode,
            "startDate": Constants.dateOnly(resv.arrivaldate) ,
            "startTime": resv.arrivaltime,
            "endDate": Constants.dateOnly(resv.returndate),
            "endTime": resv.returntime,
            "startdatetime":resv.arrivaldate ,
            "enddatetime":resv.returndate ,
            "carCount": resv.carCount,
            "discountcode":"",
            "selectedlot":"",
            "corporatecode":"", 
            "carDetails":resv.carDetails,
            "plateNumber":resv.plateNumber,
            "personparking":resv.Personparking,
            "vesselname": resv.vesselname
        };
        setUpdateReserve(fd);
        console.log('at initial the form value');
        console.log(fd);
    }
    
    useEffect(() => {
        setLoadingStatus(true);
        fetch(`${Constants.apiUrl}/reservation/${reservationId}`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Authorization': adminToken,
          },
        })
          .then((resp) => resp.json())
          .then((data) => {
            
            console.log(data);
            if(data.code==0){
                initValues(data.reservation);
                setNumofdays(data.numofdays);
            }
            else if(data.code==-10){
                setIsExpired(true);

                //logOut(); //possible the token is not available via context
            }
            setLoadingStatus(false);
          })
          .catch((error) => {
            console.log(error);
            
          });
      }, [reservationId]);



  /*   console.log ('vslue for selected reservation');
    console.log(item);
    const airportPicked = airportList.find(obj => obj.airportlongname === item.airport);
     console.log('airportPicked ');
     console.log(airportPicked); */

    
 


    const editChange = (e) => {
        setUpdateReserve({...updateReserve, [e.target.name]: e.target.value})
        console.log({[e.target.name]: e.target.value});
    }

    const submit = async(e) => {
        e.preventDefault();
       /*  if (airportPicked) {
            setUpdateReserve((prevFormData) => ({
              ...prevFormData,
              airportCode: airportPicked.airportcode,
            }));
          } else {
            // Handle the case where no matching airport is found
            console.warn("No matching airport found for:", updateReserve.airportName);
        } */


        
        var fdata={...updateReserve,"startdatetime":updateReserve.startDate + ' ' + updateReserve.startTime,
        "enddatetime":updateReserve.endDate + ' ' + updateReserve.endTime};
        setFormData(fdata)
        console.log(JSON.stringify(fdata));
        try {
            const resp = await fetch(`${Constants.apiUrl}/reservation/${item.reservationid}/search`, {
              method: 'POST',
              body: JSON.stringify(fdata),
              headers: {
                'Content-Type': 'application/json',
                'Authentication': adminToken
              },
            });
            
            const responseData = await resp.json();
            console.log(adminToken);
            console.log(responseData);
            setSearchData(responseData)
  
            if (responseData.code === 0) {
                navigate('/admin/search/'+item.reservationid)
                setIsFormSubmitted(true)
            } else if (responseData.code === -10){
                setIsExpired(true);

            }else {
              console.log(responseData.status);
            }
          } catch (error) {
            console.error(error);
          }
    }

function formatDateTime(inputDateTime) {
  const options = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };

  const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
  return formattedDateTime;
}

const currentDate = new Date().toISOString().split('T')[0];


  return (
    <div className='modify-reservations'>
      
       {loadingStatus && <div className={ loadingStatus ? "congrats show" : "congrats"}>
                <Loading message={`Loading Reservation `} />
            </div>}
      {!loadingStatus && <div className="admin-body">
            <Sidebar />
            <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
                <Head />
          { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}

                <div className="body">
                    <h1>Modify Reservation - <span>{item.parkinglotname}</span></h1>
                    <div className="form-receipt">
                        <form onSubmit={submit}>
                            <div className="input">
                                <label htmlFor="modify-airport">Airport</label>
                                <select name="airportCode" id="modify-airport" value={updateReserve.airportName} onChange={editChange}>
                                    {
                                        airportList.map((item, id) => {return (
                                            <option value={item.airportcode} key={id}>{item.airportlongname}</option>
                                        )})
                                    }
                                </select>
                            </div>
                            <div className="input">
                                <label htmlFor="modify-arrivalDate">Arrival Date</label>
                                <input type="date" name="startDate" id="modify-arrivalDate" value={updateReserve.startDate} onChange={editChange}  required/>
                            </div>
                            <div className="input">
                                <label htmlFor="modify-arrivalTime">Arrival Time</label>
                                <select name="startTime" id="modify-arrivalTime" value={updateReserve.startTime} onChange={editChange} >
                                {
                                    timeArray.map((time, id) => {
                                        return(
                                            <option key={id} value={time.value}>{time.time}</option>
                                        )
                                    })
                                }
                                </select>
                            </div>
                            <div className="input">
                                <label htmlFor="modify-returnDate">Return Date</label>
                                <input type="date" name="endDate" id="modify-returnDate" value={updateReserve.endDate} onChange={editChange} min={updateReserve.startDate} required/>
                            </div>
                            <div className="input">
                                <label htmlFor="modify-returnTime">Return Time</label>
                                <select name="endTime" id="modify-returnTime" value={updateReserve.endTime} onChange={editChange}>
                                {
                                    timeArray.map((time, id) => {
                                        return(
                                            <option key={id} value={time.value}>{time.time}</option>
                                        )
                                    })
                                }
                                </select>
                            </div>
                            <button className="save-btn" type='submit'>Modify Reservation <FontAwesomeIcon icon={faSave} /></button>
                        </form>
                        <div className="receipt">
                            <div className="header">
                                <h3>Reservation Details</h3>
                                <p>{item.parkinglotname} - {numofdays} days of parking with {item.carCount} car at ${item.dailyrate}/day/car</p>
                            </div>
                            <div className="receipt-details">
                                <div className="each-detail">
                                    <p className="title">Check In</p>
                                    <p className="title-info">{formatDateTime(item.arrivaldate)}</p>
                                </div>
                                <div className="each-detail">
                                    <p className="title">Check Out</p>
                                    <p className="title-info">{formatDateTime(item.returndate)}</p>
                                </div>
                                <div className="each-detail">
                                    <p className="title">Sub Total</p>
                                    <p className="title-info">${Constants.formatCurrency(item.totalpaid) }</p>
                                </div>
                                <div className="each-detail">
                                    <p className="title">Taxes & Fees</p>
                                    <p className="title-info">${Constants.formatCurrency(item.tax) }</p>
                                </div>
                                <div className="each-detail">
                                    <p className="title">Total</p>
                                    <p className="title-info total">${Constants.formatCurrency(item.totalpaid)}</p>
                                </div>
                                <div className="each-detail">
                                    <p className="title">Paid Online</p>
                                    <p className="title-info paid-online">${Constants.formatCurrency(item.totalpaid)}</p>
                                </div>
                                <div className="each-detail">
                                    <p className="title">Due at Lot</p>
                                    <p className="title-info due">${Constants.formatCurrency(item.payatlot)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}  
    </div>
  )
}

export default Modify