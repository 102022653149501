import React from 'react';
import TopBar from '../../components/User/TopBar'
import '../../styles/User/articlepage.css'
import { useParams } from 'react-router-dom'
import { articles } from '../../data/articles';

function ArticlePage() {
    const { articleId, itemId } = useParams();

    const category = articles.filter(category => category.title === articleId);

    const newId = parseInt(itemId);

    const article = category.map( cat => cat.articles.filter(item => item.id === newId));

    const articleObj = article[0][0]; 

  return (
    <div className='article-page'>
        <TopBar />
        <h1>{articleObj.name}</h1>
        <p className="data">
            
        </p>
    </div>
  )
}

export default ArticlePage