export const airportCodes = [
    {
        code: 'ABQ',
        name: 'ABQ Albuquerque Int’l',
        googleLink: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=ABQ+Albuquerque+Int%27l,+2200+Sunport+Boulevard+SE,+Albuquerque,+NM+87106',
        mapquestLink: 'https://www.mapquest.com/us/nm/albuquerque/87106-3247/2200-sunport-blvd-se-35.04838,-106.6168'
    },
    {
        code: 'ALB',
        name: "ALB Albany Int'l Airport",
        googleLink: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=ALB+Albany+Int%27l+Airport,+737+Albany+Shaker+Road+,+Albany,+NY+12211',
        mapquestLink: 'https://www.mapquest.com/us/new-york/albany-intl-airport-442390873'
    },
    {
        code: 'ATL',
        name: 'Atlanta (ATL)',
        googleLink: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=Atlanta+(ATL),+6000+N+Terminal+Parkway,+Atlanta,+GA+30337',
        mapquestLink: 'https://www.mapquest.com/us/georgia/hartsfield-jackson-atlanta-international-airport-435474044'
    },
    {
        code: 'BHM',
        name: 'Birmingham (BHM)',
        googleLink: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=Birmingham+(BHM),+5900+Messer+Airport+Highway+,+Birmingham,+AL+35212',
        mapquestLink: 'https://www.mapquest.com/us/al/birmingham/35212-1090/5900-messer-airport-hwy-33.55763,-86.74811'
    },
    {
        code: 'BNA',
        name: '	Nashville (BNA)',
        googleLink: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=Nashville+(BNA),+1+Terminal+Drive,+Nashville,+TN+37214',
        mapquestLink: 'https://www.mapquest.com/us/tn/nashville/37214-4100/1-terminal-dr-36.13151,-86.66931'
    },
    {
        code: 'BOS',
        name: 'Boston Logan (BOS)',
        googleLink: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=Boston+Logan+(BOS),+1+Harborside+Drive,+Boston,+MA+02128',
        mapquestLink: 'https://www.mapquest.com/us/ma/boston/02128-2916/1-harborside-dr-42.36384,-71.02536'
    },
    {
        code: 'BWI',
        name: 'Baltimore (BWI)',
        googleLink: 'https://www.google.com/maps/place/7062+Elm+Rd,+Baltimore,+MD+21240,+USA/@39.1869419,-76.6779601,17z/data=!3m1!4b1!4m6!3m5!1s0x89b7e2f19420f22b:0x1d274ccd785cfcdf!8m2!3d39.1869419!4d-76.6753852!16s%2Fg%2F11c2978wmc?hl=en&entry=ttu',
        mapquestLink: 'https://www.mapquest.com/us/md/bwi-airport/21240-1000/7062-elm-rd-39.18845,-76.67592'
    },
    {
        code: 'CHS',
        name: "CHS Charleston Int'l",
        googleLink: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=CHS+Charleston+Int%27l,+5500+International+Boulevard,+Charleston,+SC+29418',
        mapquestLink: 'https://www.mapquest.com/us/sc/north-charleston/29418-6932/5500-international-blvd-32.88487,-80.0372'
    },
    {
        code: 'CLP',
        name: 'Cape Liberty Cruiseport',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Cape%20Liberty%20Cruiseport,+14%20Port%20Terminal%20Boulevard,+Bayonne,+NJ+07002',
        mapquestLink: 'http://www.mapquest.com/maps?name=Cape%20Liberty%20Cruiseport&city=Bayonne&state=NJ&address=14%20Port%20Terminal%20Boulevard&zipcode=07002&country=US'
    },
    {
        code: 'CLT',
        name: "CLT Charlotte Douglas Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=CLT%20Charlotte%20Douglas%20Int%27l,+5501%20Josh%20Birmingham%20Parkway,+Charlotte,+NC+28208',
        mapquestLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=CLT%20Charlotte%20Douglas%20Int%27l,+5501%20Josh%20Birmingham%20Parkway,+Charlotte,+NC+28208'
    },
    {
        code: 'DCA',
        name: 'DCA Ronald Reagan National',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=DCA%20Ronald%20Reagan%20National,+2401%20Smith%20Boulevard,+Arlington,+VA+22202',
        mapquestLink: 'http://www.mapquest.com/maps?name=DCA%20Ronald%20Reagan%20National&city=Arlington&state=VA&address=2401%20Smith%20Boulevard&zipcode=22202&country=US'
    },
    {
        code: 'DFW',
        name: 'Dallas/Fort Worth (DFW)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Dallas/Fort%20Worth%20(DFW),+3200%20East%20Airfield%20Drive,+DFW%20Airport,+TX+75261',
        mapquestLink: 'http://www.mapquest.com/maps?name=Dallas/Fort%20Worth%20(DFW)&city=DFW%20Airport&state=TX&address=3200%20East%20Airfield%20Drive&zipcode=75261&country=US'
    },
    {
        code: 'DTW',
        name: 'Detroit Metro (DTW)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Detroit%20Metro%20(DTW),+31399%20East%20Service%20Drive,+Romulus,+MI+48242',
        mapquestLink: 'http://www.mapquest.com/maps?name=Detroit%20Metro%20(DTW)&city=Romulus&state=MI&address=31399%20East%20Service%20Drive&zipcode=48242&country=US'
    },
    {
        code: 'EWR',
        name: 'Newark Liberty (EWR)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Newark%20Liberty%20(EWR),+3%20Brewster%20Road,+Newark,+NJ+07114',
        mapquestLink: 'http://www.mapquest.com/maps?name=Fort%20Lauderdale%20Airport%20(FLL)&city=Fort%20Lauderdale&state=FL&address=100%20Aviation%20Boulevard&zipcode=33315&country=US'
    },
    {
        code: 'FLL',
        name: 'Fort Lauderdale Airport (FLL)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Fort%20Lauderdale%20Airport%20(FLL),+100%20Aviation%20Boulevard,+Fort%20Lauderdale,+FL+33315',
        mapquestLink: 'http://www.mapquest.com/maps?name=Fort%20Lauderdale%20Airport%20(FLL)&city=Fort%20Lauderdale&state=FL&address=100%20Aviation%20Boulevard&zipcode=33315&country=US'
    },
    {
        code: 'HOU',
        name: 'Houston Hobby (HOU)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Houston%20Hobby%20(HOU),+7800%20Airport%20Boulevard%20,+Houston,+TX+77061',
        mapquestLink: 'http://www.mapquest.com/maps?name=Houston%20Hobby%20(HOU)&city=Houston&state=TX&address=7800%20Airport%20Boulevard%20&zipcode=77061&country=US'
    },
    {
        code: 'IAD',
        name: 'Dulles Washington (IAD)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Dulles%20Washington%20(IAD),+1%20Saarinen%20Circle,+Dulles,+VA+20166',
        mapquestLink: 'http://www.mapquest.com/maps?name=Dulles%20Washington%20(IAD)&city=Dulles&state=VA&address=1%20Saarinen%20Circle&zipcode=20166&country=US'
    },
    {
        code: 'IAH',
        name: 'Houston George Bush (IAH)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Houston%20George%20Bush%20(IAH),+2800%20North%20Terminal%20Road,+Houston,+TX+77032',
        mapquestLink: 'http://www.mapquest.com/maps?name=Houston%20George%20Bush%20(IAH)&city=Houston&state=TX&address=2800%20North%20Terminal%20Road&zipcode=77032&country=US'
    },
    {
        code: 'IND',
        name: "	IND Indianapolis Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=IND%20Indianapolis%20Int%27l,+7800%20Col.%20H.%20Weir%20Cook%20Memorial%20Drive,+Indianapolis,+IN+46241',
        mapquestLink: 'http://www.mapquest.com/maps?name=IND%20Indianapolis%20Int%27l&city=Indianapolis&state=IN&address=7800%20Col.%20H.%20Weir%20Cook%20Memorial%20Drive&zipcode=46241&country=US'
    },
    {
        code: 'JAX',
        name: 'Jacksonville (JAX)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Jacksonville%20(JAX),+2400%20Yankee%20Clipper%20Drive,+Jacksonville,+FL+32218',
        mapquestLink: 'http://www.mapquest.com/maps?name=Jacksonville%20(JAX)&city=Jacksonville&state=FL&address=2400%20Yankee%20Clipper%20Drive&zipcode=32218%20&country=US'
    },
    {
        code: 'JCT',
        name: '	JCT Jacksonville Cruise Port',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=JCT%20Jacksonville%20Cruise%20Port,+9810%20August%20Drive,+Jacksonville,+FL+32226',
        mapquestLink: 'http://www.mapquest.com/maps?name=JCT%20Jacksonville%20Cruise%20Port&city=Jacksonville&state=FL&address=9810%20August%20Drive&zipcode=32226&country=US'
    },
    {
        code: 'JFK',
        name: 'New York (JFK)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=New%20York%20(JFK),+JFK%20Access%20Road%20,+New%20York,+NY+10010',
        mapquestLink: 'http://www.mapquest.com/maps?name=New%20York%20(JFK)&city=New%20York&state=NY&address=JFK%20Access%20Road%20&zipcode=10010&country=US'
    },
    {
        code: 'LAX',
        name: 'Los Angeles (LAX)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Los%20Angeles%20(LAX),+1%20World%20Way,+Los%20Angelas,+CA+90045',
        mapquestLink: 'http://www.mapquest.com/maps?name=Los%20Angeles%20(LAX)&city=Los%20Angelas&state=CA&address=1%20World%20Way&zipcode=90045&country=US'
    },
    {
        code: 'LGA',
        name: 'New York LaGuardia (LGA)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=New%20York%20LaGuardia%20(LGA),+LaGuardia%20Airport,+New%20York,+NY+11371',
        mapquestLink: 'http://www.mapquest.com/maps?name=New%20York%20LaGuardia%20(LGA)&city=New%20York&state=NY&address=LaGuardia%20Airport&zipcode=11371&country=US'
    },
    {
        code: 'MCI',
        name: 'Kansas City (MCI)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Kansas%20City%20(MCI),+601%20Brasilia%20Avenue,+Kansas%20City,+MO+64153',
        mapquestLink: 'http://www.mapquest.com/maps?name=Kansas%20City%20(MCI)&city=Kansas%20City&state=MO&address=601%20Brasilia%20Avenue&zipcode=64153&country=US'
    },
    {
        code: 'MCO',
        name: 'Orlando (MCO)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Orlando%20(MCO),+1%20Airport%20Boulevard,+Orlando,+FL+32827',
        mapquestLink: 'http://www.mapquest.com/maps?name=Orlando%20(MCO)&city=Orlando&state=FL&address=1%20Airport%20Boulevard&zipcode=32827&country=US'
    },
    {
        code: 'MDT',
        name: "MDT Harrisburg Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=MDT%20Harrisburg%20Int%27l,+One%20Terminal%20Drive,+Middletown,+PA+17057',
        mapquestLink: 'http://www.mapquest.com/maps?name=MDT%20Harrisburg%20Int%27l&city=Middletown&state=PA&address=One%20Terminal%20Drive&zipcode=17057&country=US'
    },
    {
        code: 'MEM',
        name: "MEM Memphis Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=MEM%20Memphis%20Int%27l,+2600%20Rental%20Road,+Memphis,+TN+38118',
        mapquestLink: 'http://www.mapquest.com/maps?name=MEM%20Memphis%20Int%27l&city=Memphis&state=TN&address=2600%20Rental%20Road&zipcode=38118&country=US'
    },
    {
        code: 'MHT',
        name: 'Manchester-Boston (MHT)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Manchester-Boston%20(MHT),+One%20Airport%20Road,+Manchester,+NH+03103',
        mapquestLink: 'http://www.mapquest.com/maps?name=Manchester-Boston%20(MHT)&city=Manchester&state=NH&address=One%20Airport%20Road&zipcode=03103&country=US'
    },
    {
        code: 'MIA',
        name: '	Miami Airport (MIA)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Miami%20Airport%20(MIA),+2100%20NW%2042nd%20Avenue,+Miami,+FL+33142',
        mapquestLink: 'http://www.mapquest.com/maps?name=Miami%20Airport%20(MIA)&city=Miami&state=FL&address=2100%20NW%2042nd%20Avenue&zipcode=33142&country=US'
    },
    {
        code: 'MSP',
        name: "MSP Minneapolis-St Paul Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=MSP%20Minneapolis-St%20Paul%20Int%27l,+4300%20Glumack%20Drive,+St.%20Paul,+MN+55111',
        mapquestLink: 'http://www.mapquest.com/maps?name=MSP%20Minneapolis-St%20Paul%20Int%27l&city=St.%20Paul&state=MN&address=4300%20Glumack%20Drive&zipcode=55111&country=US'
    },
    {
        code: 'MSY',
        name: 'New Orleans Airport (MSY)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=New%20Orleans%20Airport%20(MSY),+900%20Airline%20Drive,+Kenner,+LA+70062',
        mapquestLink: 'http://www.mapquest.com/maps?name=New%20Orleans%20Airport%20(MSY)&city=Kenner&state=LA&address=900%20Airline%20Drive&zipcode=70062&country=US'
    },
    {
        code: 'NOP',
        name: 'New Orleans Cruiseport',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=New%20Orleans%20Cruiseport,+1350%20Port%20of%20New%20Orleans%20Place,+New%20Orleans,+LA+70160',
        mapquestLink: 'http://www.mapquest.com/maps?name=New%20Orleans%20Cruiseport&city=New%20Orleans&state=LA&address=1350%20Port%20of%20New%20Orleans%20Place&zipcode=70160&country=US'
    },
    {
        code: 'ONT',
        name: "ONT Ontario Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=ONT%20Ontario%20Int%27l,+1923%20East%20Avion%20Drive,+Ontario,+CA+91761',
        mapquestLink: 'http://www.mapquest.com/maps?name=ONT%20Ontario%20Int%27l&city=Ontario&state=CA&address=1923%20East%20Avion%20Drive&zipcode=91761&country=US'
    },
    {
        code: 'ORD',
        name: "Chicago O'Hare (ORD)",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Chicago%20O%27Hare%20(ORD),+2985%20Mannheim%20Road,+Des%20Plaines,+IL+60018',
        mapquestLink: 'http://www.mapquest.com/maps?name=Chicago%20O%27Hare%20(ORD)&city=Des%20Plaines&state=IL&address=2985%20Mannheim%20Road&zipcode=60018&country=US'
    },
    {
        code: 'PBI',
        name: 'Palm Beach (PBI)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Palm%20Beach%20(PBI),+1000%20James%20L%20Turnage%20Boulevard,+West%20Palm%20Beach,+FL+33415',
        mapquestLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Palm%20Beach%20(PBI),+1000%20James%20L%20Turnage%20Boulevard,+West%20Palm%20Beach,+FL+33415'
    },
    {
        code: 'PCS',
        name: 'PCS Port Canaveral Cruise Port',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=PCS%20Port%20Canaveral%20Cruise%20Port,+445%20Challenger%20Road,%20Suite%20301,+Cape%20Canaveral,+FL+32920',
        mapquestLink: 'http://www.mapquest.com/maps?name=PCS%20Port%20Canaveral%20Cruise%20Port&city=Cape%20Canaveral&state=FL&address=445%20Challenger%20Road,%20Suite%20301&zipcode=32920&country=US'
    },
    {
        code: 'PHL',
        name: "PHL Philadelphia Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=PHL%20Philadelphia%20Int%27l,+8000%20Essington%20Avenue,+Philadelphia,+PA+19153',
        mapquestLink: 'http://www.mapquest.com/maps?name=PHL%20Philadelphia%20Int%27l&city=Philadelphia&state=PA&address=8000%20Essington%20Avenue&zipcode=19153&country=US'
    },
    {
        code: 'PHX',
        name: 'Phoenix Sky Harbor (PHX)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Phoenix%20Sky%20Harbor%20(PHX),+3400%20E%20Sky%20Harbor%20Boulevard,+Phoenix,+AZ+85034',
        mapquestLink: 'http://www.mapquest.com/maps?name=Phoenix%20Sky%20Harbor%20(PHX)&city=Phoenix&state=AZ&address=3400%20E%20Sky%20Harbor%20Boulevard&zipcode=85034&country=US'
    },
    {
        code: 'PIT',
        name: "PIT Pittsburgh Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=PIT%20Pittsburgh%20Int%27l,+1000%20Airport%20Boulevard,+Coraopolis,+PA+15108',
        mapquestLink: 'http://www.mapquest.com/maps?name=PIT%20Pittsburgh%20Int%27l&city=Coraopolis&state=PA&address=1000%20Airport%20Boulevard&zipcode=15108&country=US'
    },
    {
        code: 'POG',
        name: 'Port of Galveston',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Port%20of%20Galveston,+2502%20Harborside%20Drive,+Galveston,+TX+77550',
        mapquestLink: 'http://www.mapquest.com/maps?name=Port%20of%20Galveston&city=Galveston&state=TX&address=2502%20Harborside%20Drive&zipcode=77550&country=US'
    },
    {
        code: 'POM',
        name: 'Miami Cruiseport',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Miami%20Cruiseport,+1015%20North%20America%20Way,+Miami,+FL+33132',
        mapquestLink: 'http://www.mapquest.com/maps?name=Miami%20Cruiseport&city=Miami&state=FL&address=1015%20North%20America%20Way&zipcode=33132&country=US'
    },
    {
        code: 'POT',
        name: 'Tampa Cruiseport',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Tampa%20Cruiseport,+1101%20Channelside%20Dr,+Tampa,+FL+33602',
        mapquestLink: 'http://www.mapquest.com/maps?name=Tampa%20Cruiseport&city=Tampa&state=FL&address=1101%20Channelside%20Dr&zipcode=33602&country=US'
    },
    {
        code: 'PTE',
        name: 'Fort Lauderdale Cruiseport',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Fort%20Lauderdale%20Cruiseport,+1850%20Eller%20Drive,+Fort%20lauderdale,+FL+33316',
        mapquestLink: 'http://www.mapquest.com/maps?name=Fort%20Lauderdale%20Cruiseport&city=Fort%20lauderdale&state=FL&address=1850%20Eller%20Drive&zipcode=33316&country=US'
    },
    {
        code: 'PVD',
        name: 'Providence T.F. Green (PVD)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Providence%20T.F.%20Green%20(PVD),+2000%20Post%20Road,+Warwick,+RI+02886',
        mapquestLink: 'http://www.mapquest.com/maps?name=Providence%20T.F.%20Green%20(PVD)&city=Warwick&state=RI&address=2000%20Post%20Road&zipcode=02886&country=US'
    },
    {
        code: "RDU",
        name: "RDU Raleigh-Durham Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=RDU%20Raleigh-Durham%20Int%27l,+2020%20Hospitality%20Court,+Morrisville,+NC+27560',
        mapquestLink: 'http://www.mapquest.com/maps?name=RDU%20Raleigh-Durham%20Int%27l&city=Morrisville&state=NC&address=2020%20Hospitality%20Court&zipcode=27560&country=US'
    },
    {
        code: 'RSW',
        name: "RSW Southwest Florida Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=RSW%20Southwest%20Florida%20Int%27l,+11000%20Terminal%20Access%20Road,+Fort%20Myers,+FL+33913',
        mapquestLink: 'http://www.mapquest.com/maps?name=RSW%20Southwest%20Florida%20Int%27l&city=Fort%20Myers&state=FL&address=11000%20Terminal%20Access%20Road&zipcode=33913&country=US'
    },
    {
        code: 'SAV',
        name: "SAV Savannah/Hilton Head Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=SAV%20Savannah/Hilton%20Head%20Int%27l,+400%20Airways%20Avenue,+Savannah,+GA+31408',
        mapquestLink: 'http://www.mapquest.com/maps?name=SAV%20Savannah/Hilton%20Head%20Int%27l&city=Savannah&state=GA&address=400%20Airways%20Avenue&zipcode=31408&country=US'
    },
    {
        code: 'SEA',
        name: 'Seattle-Tacoma (SEA)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Seattle-Tacoma%20(SEA),+17801%20International%20Boulevard,+Seattle,+WA+98158',
        mapquestLink: 'http://www.mapquest.com/maps?name=Seattle-Tacoma%20(SEA)&city=Seattle&state=WA&address=17801%20International%20Boulevard&zipcode=98158&country=US'
    },
    {
        code: 'SFO',
        name: "SFO San Francisco Int'l",
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=SFO%20San%20Francisco%20Int%27l,+S%20McDonnell%20Rd%20&%20S%20Link%20Rd,+San%20Francisco,+CA+94128',
        mapquestLink: 'hhttp://www.mapquest.com/maps?name=SFO%20San%20Francisco%20Int%27l&city=San%20Francisco&state=CA&address=S%20McDonnell%20Rd%20&%20S%20Link%20Rd&zipcode=94128&country=US'
    },
    {
        code: 'STL',
        name: 'St. Louis (STL)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=St.%20Louis%20(STL),+10701%20Lambert%20International%20Blvd%20,+St.%20Louis,+MO+63145',
        mapquestLink: 'http://www.mapquest.com/maps?name=St.%20Louis%20(STL)&city=St.%20Louis&state=MO&address=10701%20Lambert%20International%20Blvd%20&zipcode=63145&country=US'
    },
    {
        code: 'TPA',
        name: 'Tampa Airport (TPA)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Tampa%20Airport%20(TPA),+4100%20George%20J.%20Bean%20Parkway,+Tampa,+FL+33607',
        mapquestLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Tampa%20Airport%20(TPA),+4100%20George%20J.%20Bean%20Parkway,+Tampa,+FL+33607'
    },
    {
        code: 'TYS',
        name: 'Knoxville McGhee Tyson (TYS)',
        googleLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Knoxville%20McGhee%20Tyson%20(TYS),+2055%20Alcoa%20Highway,+Alcoa,+TN+37701',
        mapquestLink: 'http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Knoxville%20McGhee%20Tyson%20(TYS),+2055%20Alcoa%20Highway,+Alcoa,+TN+37701'
    },
]