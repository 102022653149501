import React, { useState } from 'react'
import LoginImg from '../../assets/login.jpg';
import '../../styles/User/forgot.css'
import { Link } from 'react-router-dom';
import * as Constants from '../../constants';
import ReactGA from 'react-ga';
function ForgotPassword() {

    const [email, setEmail] = useState('')

    const handleChange = (e) => {
        setEmail(e.target.value)
    } 

    const submit = async(e) => {
        e.preventDefault()

        const resp = await fetch(`${Constants.apiUrl}/reservation/forgetpassword`, {
            method: 'POST',
            body: email,
            headers: {
              'Content-Type': 'application/json',
            //   'Authorization': '',
            },
        })
        console.log(resp);
    }
    ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className='forgot-page'>
        <img src={LoginImg} alt="Logging In Poster" />
        <div className="form">
            <div className="container">
                <div className="text">
                    <p className='welcome'>Welcome to <span>Airport Parking Inc</span></p>
                    <h2 className="bigText">Forgot Password</h2>
                </div>
                <div className="input">
                    <label htmlFor="email">E-mail</label>
                    <input type="email" id='email' name='email' value={email} onChange={handleChange}/>
                </div>
                <div className="btns">
                    <button onClick={submit}>Send Recovery Mail</button>
                    <Link to='/reservation/login'>Back to Login</Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ForgotPassword