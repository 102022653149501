import moment from "moment";
export const baseUrl="https://app.airportparkinginc.com";
//export const baseUrl="https://localhost:7101";

export const excelPath=`${baseUrl}/`;

export const apiUrl=`${baseUrl}/api`;

export const formatDateTime=(inputDateTime)=> {
    /* const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
  
    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
    return formattedDateTime; */
    return moment(inputDateTime).format("MMM DD, YYYY HH:mm");
  }
  export const formatDate=(inputDateTime)=> {
  
  try {
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    
      hour12: true,
    };
  
    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
    return formattedDateTime;
  } catch{
    return 'N/A';
  }
  }
  export const formatTime=(inputDateTime)=> {
    const options = {
     
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
  
    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
    return formattedDateTime;
  }
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
  });
  export const formatCurrency=(num)=>{
    // return parseFloat(num).toFixed(2);
    return formatter.format(num);
   }
  export const dateOnly=(value) =>{
    let dstring=  moment(value).format("YYYY-MM-DD");
    return dstring;
    //let d=new Date(dstring);
    //return d;
   }

   export function seo(data = {}) {
    data.title = data.title || 'Airport Parking Inc.';
    data.metaDescription = data.metaDescription || 'Guaranteed Airport Parking Inc.';
  
    document.title = data.title;
    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
  }

  export const removeSpace=(v)=>{
    if (v==null)
     return 'NAApll';
   return v.replace('-','');
  }

  export const duration=(start, end)=>{
    let mstart = moment(start);
    let mend = moment(end);
   // let days = dateOne.diff(dateTwo, 'days')
    var duration = moment.duration(mend.diff(mstart));
    //var hours = duration.asHours(); duration.asDays duration.asMinutes

      return `${duration.days()}days, ${duration.hours()}hr`
  }
  export const isDateValid=(dateStr) => {
    return !isNaN(new Date(dateStr));
  }

  export const NetworkFailed='Somehow network not connecting. Please refresh when you sure network is fine';