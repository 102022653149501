import React, {useEffect, useMemo, useState, useContext} from 'react'
import '../../styles/User/home.css'
import { Link } from 'react-router-dom'
import { ShareContext } from '../../context/ShareContext';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faCircleXmark, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import Bus from '../../assets/bus-cover.png'
import Plane from '../../assets/plane-cover.png';
import WaitArea from '../../assets/waiting-area.png';
import AirportForm from '../../components/User/AirportForm';
import Benefit1 from '../../assets/why-1.png';
import Benefit2 from '../../assets/why-2.png';
import Benefit3 from '../../assets/why-3.png';
import Benefit4 from '../../assets/why-4.png';
import OutrageousCabins from '../../assets/outrageouscabins-2.jpg';
import TrustBox from '../../components/trustbox';
import * as Constants from '../../constants';
import ReactGA from 'react-ga';


function Home() {
    
    const { airportList } = useContext(ShareContext)

    // console.log(airportList);
    Constants.seo({title:'Airport Parking Reservations | Quick And Easy Airport Parking',
    metaDescription:'Airport Parking Reservations. Quick And Easy Long Term Offsite Airport Parking Reservations. 24-hour Video Surveillance. Convenient Shuttle Service.'})

    const benefits = [
        {
            id: 1,
            pic: Benefit1,
            text: 'We offer the lowest airport parking fees saving you 40-70% off airport prices',
        },
        {
            id: 2,
            pic: Benefit2,
            text: 'We offer complimentary shuttles to and from your airport terminal',
        },
        {
            id: 3,
            pic: Benefit3,
            text: 'Your reservations are guaranteed at our privately owned facilities',
        },
        {
            id: 4,
            pic: Benefit4,
            text: 'Our parking facilities are safe and secure',
        }
    ]

    const bgObject = useMemo(() => {
        const newArray =[
            {
                img: Bus,
                textHead: 'BOOK YOUR RESERVATIONS',
                text: 'Once you have selected a parking facility, you will be directed to our secure checkout page. Returning customers can login and have their basic profile information pre-filled on the registration form. New customers would have to fill the registration form'
            },
            {
                img: Plane,
                textHead: 'AFTER BOOKING RESERVATIONS',
                text: 'Once you book your reservation make sure you print out your receipt as this will be requested at the parking facility on arrival. Your receipt includes your travel itinerary; the amount paid online, outstanding amount to be paid at lot (if applicable) and directions to the parking facility.'
            },
            {
                img: WaitArea,
                textHead: 'SELECT A PARKING FACILITY',
                text: 'We will display available parking facilities close to the selected airport. You will have the ability to sort by lot’s name, and price. You will also have the ability to map direction from your location to your selected parking facility.'
            }
        ]

        return newArray;
    }, []) 
    const [cancelAlert, setCancelAlert] = useState('alert-message')
    const [currImage, setCurrImage] = useState(1);
    const [currBg, setCurrBg] = useState(bgObject[0]);

    const { ref: whyRef, inView: isWhyInView } = useInView();
    const { ref: mainRef, inView: isMainInView } = useInView();
    const { ref: facilitiesRef, inView: isFacilitiesInView } = useInView()

    useEffect(() => {

        if (currImage === 4) {
            setCurrImage(1)
        } else if (currImage === 0){
            setCurrImage(3)
        };

        const timeOut  = setInterval(() => {
            setCurrImage(prev => (prev + 1) % bgObject.length)
        }, 10000)

        currImage === 1 ? setCurrBg(bgObject[0]) : currImage === 2 ? setCurrBg(bgObject[1]) : currImage === 3 ? setCurrBg(bgObject[2]) : setCurrBg(bgObject[0])

        return () => clearInterval(timeOut)
    }, [currImage, bgObject.length, bgObject])

    ReactGA.pageview(window.location.pathname + window.location.search);
    
  return (
    <main>
        <div className={cancelAlert}>
            <h3>Customer service hours: 8 AM to 6 PM Monday - Sunday</h3>
            <div className="terms-home">
                <Link to='/terms-of-use'>See Cancellation Policy in Terms of Use</Link>
                <p>| $5.00 service fee is not refundable</p>
            </div>
            <FontAwesomeIcon icon={faCircleXmark} className='cancel-alert' onClick={() => setCancelAlert('alert-message cancel')}/>
        </div>
        <div className={ isMainInView ? "main-box show" : "main-box"} ref={mainRef}>
            <div className="form">
                <AirportForm />
            </div>
            <div className="images-info">
            {bgObject.map((bg, index) => (
                <img
                key={index}
                src={bg.img}
                alt={`Background ${index}`}
                loading='lazy'
                className={index === currImage - 1 ? '' : 'hidden'}
                />
            ))}
                <div className="text">
                    <h1>{currBg.textHead}</h1>  
                    <p>{currBg.text}</p> 
                    <div className="nav-dots">
                        <div className={ currImage === 1 ? "circle active" : "circle" }></div>
                        <div className={ currImage === 2 ? "circle active" : "circle" }></div>
                        <div className={ currImage === 3 ? "circle active" : "circle" }></div>
                    </div> 
                </div>
                <div className="arrows">
                    <div className="arrow" onClick={() => setCurrImage(prev => prev - 1)}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </div>
                    <div className="arrow" onClick={() => setCurrImage(prev => prev + 1)}>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                </div>
            </div>
        </div>
        <div className={ isWhyInView ? "why-airportinc show" : "why-airportinc"} ref={whyRef}>
            <h1>Why you should park with us?</h1>
            <div className="items">
                { benefits.map(benefit => {
                    return(
                        <div className="item" key={benefit.id}>
                            <img src={benefit.pic} alt="Benefits of Airport Parking Inc" loading='lazy'/>
                            <p>{benefit.text}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        <div className={ isFacilitiesInView ? "facilities show" : "facilities"} ref={facilitiesRef}>
            <div className="airport-facilities">
                <h2>Our Airport Facilities</h2>
                <ul className='airport-list'>
                    {
                        airportList.map((airport, id) => {
                            return(
                                <li key={id}>
                                    <Link to={`/airports/${airport.airportcity}`} className='airport-name'>
                                        <FontAwesomeIcon icon={faLocationDot} />
                                        {airport.airportlongname}
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="sponsors-ratings">
                <div className="sponsors">
                    <h3>Looking for a getaway vacation?</h3>
                    <p>Check out Outrageous Cabins in Sevierville Tennessee today! </p>
                    <Link to="https://www.outrageouscabins.com/" target="_blank" rel='noreferrer'>
                        <img src={OutrageousCabins} alt="Outrageous Cabins" loading='lazy'/>
                    </Link>
                </div>
                <div className="ratings">
                {/* <div class="trustpilot-widget" data-locale="en-US" data-template-id="539ad0ffdec7e10e686debd7" data-businessunit-id="54a69f2a0000ff00057c74e0" data-style-height="350px" data-style-width="100%" data-theme="light" data-stars="4,5"></div> */}
                <TrustBox />
                </div>
            </div>
        </div>
    </main>
  )
}

export default Home