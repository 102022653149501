import React, { useState, useContext } from 'react'
import '../../styles/Admin/corporate.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faClose, faRotateRight, faSearch, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter} from '@fortawesome/free-solid-svg-icons';
import { states } from '../../data/userstates'
import { ShareContext } from '../../context/ShareContext'

function Corporate() {

    const { isSidebarClosed } = useContext(ShareContext)

    const corporateData = [
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ATravel',
        //     corporateName: 'A Mason Destination',
        //     contactName: 'Ann Mason',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '2900 Neil Court Palm Harbour, FL 34684',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
    ]
    const [openCreate, setOpenCreate] = useState(false);
    const [expandForm, setExpandForm] = useState(false);
    const [openedForm, setOpenedForm] = useState('create')
    const formObj = {
        corporateId: '',
        corporateName: '',
        contactName: '',
        corporateCity: '',
        state: '',
        corporateZip: '',
        address: '',
        addressTwo: '',
        email: '',
        phone: '',
        fax:'',
        updatedDate: '',
        parkingLot: '',
        dailyRate: '',
        monthlyRate: '',
        description: '',
        startDate: '',
        endDate: ''
};
    const [corporateForm, setCorporateForm] = useState(formObj)
    const [editData, setEditData] = useState({})
    const [searchValue, setSearchValue] = useState('');
    const [tableState, setTableState] =  useState(corporateData);
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false)

    const formChange = (e) => {
        setCorporateForm({...corporateForm, [e.target.name]: e.target.value})
    }

    const changeEdit = (e) => {
        setEditData({...editData, [e.target.name]: e.target.value})
    }

    const create = () => {
        setOpenCreate(true);
        setOpenedForm('create')
        setEditData({corporateId: '',
        corporateName: '',
        contactName: '',
        corporateCity: '',
        state: '',
        corporateZip: '',
        address: '',
        addressTwo: '',
        email: '',
        phone: '',
        fax:'',
        updatedDate: '',
        parkingLot: '',
        dailyRate: '',
        monthlyRate: '',
        description: '',
        startDate: '',
        endDate: ''})
    }

    const retrieve = (id) => {
        const theData = tableState.find(item =>  item.corporateId === id)

        return theData;
    }

    const editClick = (id) => {
        setOpenCreate(true);
        setOpenedForm('edit')

        const idData = retrieve(id);

        setEditData(idData)
    }

    const addDiscount = (id) => {
        setOpenCreate(true);
        setOpenedForm('add-discount')

        const idData = retrieve(id);

        setEditData(idData)
    }

    const viewDiscount = (id) => {
        setOpenCreate(true);
        setOpenedForm('view-discount')

        const idData = retrieve(id);

        setEditData(idData)
    }

    const searchBar = (e) => {
        setSearchValue(e.target.value)
    }

    const filteredData = tableState?.filter(item => {
        const searchMatch = item.corporateName.toLowerCase().includes(searchValue.toLowerCase());
        return searchMatch 
    })

    const closePopUp = () => {
        setOpenCreate(false)
        setCorporateForm(formObj) 
    }

    const openDeleteBox = (id) => {
        setIsDialogBoxOpen(true);
    
        const data = retrieve(id)
    
        setEditData(data)
    }

    const closeDialogBox = () => {
        setIsDialogBoxOpen(false);
    }

    const deleteIt = (id) => {
        const updatedArray = tableState?.filter(obj => obj.corporateId !== id)
      
        setTableState(updatedArray)
        setIsDialogBoxOpen(false)
      }
    

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="corporate">
                <div className="head">
                    <h1>Corporate</h1>
                    <p>See and add corporate accounts</p>
                </div>
                <div className="body-wrapper">
                    <div className="head-block">
                        <h3>Corporate Accounts</h3>
                        <div className="head-icons">    
                            <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                        </div>
                    </div>
                    <div className="adding-client">
                        <button onClick={() => create()}>Create New Account</button>
                        <div className="search-input">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="text" placeholder='Search Corporate Accounts' value={searchValue} onChange={searchBar}/>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Corporate ID</th>
                                    <th>Corporate Name</th>
                                    <th>Contact Name</th>
                                    <th>Address</th>
                                    <th>E-mail</th>
                                    <th>Phone</th>
                                    <th>Fax</th>
                                    <th>Updated Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.map((acc, idx) => { return(
                                        <tr key={idx}>
                                            <td>{acc.corporateId}</td>
                                            <td>{acc.corporateName}</td>
                                            <td>{acc.contactName}</td>
                                            <td>{acc.address}</td>
                                            <td>{acc.email}</td>
                                            <td>{acc.phone}</td>
                                            <td>{acc.fax}</td>
                                            <td>{acc.updatedDate}</td>
                                            <td>
                                                <ul>
                                                    <li onClick={() => editClick(acc.corporateId)}>Edit</li>
                                                    <li onClick={() => addDiscount(acc.corporateId)}>Add Discounts</li>
                                                    <li onClick={() => viewDiscount(acc.corporateId)}>View Discounts</li>
                                                    <li className='delete' onClick={() => openDeleteBox(acc.corporateId)}>Delete</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className={ openCreate ? "create-wrapper active" : "create-wrapper"}>
                        <div className="close-it" onClick={() => closePopUp()}>
                            <FontAwesomeIcon icon={faClose} />  
                        </div>
                        <div className={expandForm ? "create-account expanded": "create-account"}>
                            <div className="create-header">
                                <h3>{ openedForm === 'create' ? 'New Corporate Account' :
                                openedForm === 'edit' ? 'Edit Corporate Account'  : openedForm === 'add-discount' ? 'Create Corporate Discount' : openedForm === 'view-discount' ? 'Corporate Discounts' : 'New Corporate Account'}</h3>
                                <FontAwesomeIcon icon={expandForm ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className='close-create' onClick={() => setExpandForm(!expandForm)}/>
                            </div>
                            {
                                (openedForm === 'edit' || openedForm === 'create') &&
                                <form method="post">
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="corporateId">Corporate ID</label>
                                        <input type="text" name='corporateId' id='corporateId' value={editData.corporateId} onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="corporateName">Corporate Name</label>
                                        <input type="text" name='corporateName' id='corporateName' value={editData.corporateName} onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="address">Address 1</label>
                                    <input type="text" name='address' id='address' value={editData.address} onChange={changeEdit}/>
                                </div>
                                <div className="input">
                                    <label htmlFor="addressTwo">Address 2</label>
                                    <input type="text" name='addressTwo' id='addressTwo' value={editData.addressTwo} onChange={changeEdit}/>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="corporateCity">City</label>
                                        <input type="text" name='corporateCity' id='corporateCity' value={editData.corporateCity} onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="corporateState">State</label>
                                        <select name="state" id="corporateState" value={editData.state} onChange={changeEdit}>
                                            <option value="" disabled selected>Select State/Province</option>
                                            { states.map((state, idx) => <option key={idx} value={state.code}>{state.name}</option>) }
                                        </select>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="corporateZip">ZIP</label>
                                        <input type="text" name='corporateZip' id='corporateZip' value={editData.corporateZip} onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="contactName">Contact Name</label>
                                        <input type="text" name='contactName' id='contactName' value={editData.contactName} onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="contactEmail">Contact E-mail</label>
                                        <input type="text" name='email' id='contactEmail' value={editData.email} onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="contactPhone">Contact Phone</label>
                                        <input type="tel" name='phone' id='contactPhone' value={editData.phone} onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="contactFax">Contact Fax</label>
                                        <input type="text" name='fax' id='contactFax' value={editData.fax} onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="btns">
                                    <button type='submit'>Save</button>
                                    <p onClick={()=> closePopUp()} className='goBack'>Back to Corporate</p>
                                </div>
                            </form> 
                            }
                            {
                                openedForm === 'add-discount' &&
                                <form method="post">
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="corporateId">Corporate ID</label>
                                        <input type="text" name='corporateId' id='corporateId' value={editData.corporateId} disabled/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="parkingLot">Parking Lot</label>
                                        <select name="parkingLot" id="parkingLot" onChange={changeEdit} value={editData.parkingLot}>
                                            <option value="">All Parking Lots</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="dailyRate">Daily Rate</label>
                                    <input type="number" name='dailyRate' id='dailyRate' value={editData.dailyRate} onChange={changeEdit} required/>
                                    <p className="note">Entering 1 or greater value here will mean dollar amount. Fractional vallue means % amount (e.g. 0.9 means 90% of regular rate implying 10% discount)</p>
                                </div>
                                <div className="input">
                                    <label htmlFor="monthlyRate">Monthly Rate</label>
                                    <input type="number" name='monthlyRate' id='monthlyRate' value={editData.monthlyRate} onChange={changeEdit} required/>
                                    <p className="note">Entering 1 or greater value here will mean dollar amount. Fractional vallue means % amount (e.g. 0.9 means 90% of regular rate implying 10% discount)</p>
                                </div>
                                <div className="input">
                                    <label htmlFor="description">Description</label>
                                    <input type="text" name='description' id='description' value={editData.description} onChange={changeEdit}/>
                                </div> 
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="startDate">Start Date</label>
                                        <input type="date" name='startDate' id='startDate' value={editData.startDate} onChange={changeEdit} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="endDate">End Date</label>
                                        <input type="date" name='endDate' id='endDate' value={editData.endDate} onChange={changeEdit}/>
                                        <p className="note">Leave blank if no date</p>
                                    </div>
                                </div>   
                                <div className="btns">
                                    <button type='submit'>Save Corporate Discount</button>
                                    <p onClick={()=> closePopUp()} className='goBack'>Back to Corporate</p>
                                </div>
                            </form> 
                            }
                            {
                                openedForm === 'view-discount' &&
                                <div className="body">
                                    <div className="table-wrapper">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Corporate Code</th>
                                                <th>Parking Lot</th>
                                                <th>Daily Rate</th>
                                                <th>Monthly Rate</th>
                                                <th>Description</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Last Updated By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{editData.corporateCode}</td>
                                                <td>{editData.parkingLot}</td>
                                                <td>{editData.dailyRate}</td>
                                                <td>{editData.monthlyRate}</td>
                                                <td>{editData.description}</td>
                                                <td>{editData.startDate}</td>
                                                <td>{editData.endDate}</td>
                                                <td>{editData.updatedDate}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                    <p className="goBack" onClick={() => closePopUp()}>Back to Corporate</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={ isDialogBoxOpen ? "delete-wrapper show" : "delete-wrapper"}>
                <div className="dialog">
                    <div className="head">
                        <h4>Delete Corporate Account</h4>
                        <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                    </div>
                    <div className="main">
                        <h3>Are you sure you want to delete Corporate Account: { editData.corporateName }?</h3>
                        <p>This action will permanently delete this Corporate Account. Please click 'Delete Corporate Account' to continue or Cancel to abort!</p>
                        <div className="btns">
                            <button onClick={() => deleteIt(editData.corporateId)}>Delete Corporate Account</button>
                            <p onClick={() => closeDialogBox()}>Cancel</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Corporate