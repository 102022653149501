import React, { useContext } from 'react'
import '../../styles/User/searchresult.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import AdminAirportForm from '../../components/Admin/AirportForm'
import { ShareContext } from '../../context/ShareContext';
import { Link } from 'react-router-dom';
import * as Constants from '../../constants';
import { useParams } from 'react-router-dom'

function AdminSearchResult() {

    const { formData, isFormSubmitted, searchData } = useContext(ShareContext)
    const {reservationId} = useParams();
    const mainResvId=reservationId==null?0:reservationId;
  //  const fetchurl=reservationId==null || reservationId==0?'https://app.airportparkinginc.com/api/reservation/search': `https://app.airportparkinginc.com/api/reservation/${reservationId}/search`;
   
  return (
    <div className='search-result'>
        <Head />
        <div className="body">
            <AdminAirportForm reservationId={reservationId==null?0:reservationId} />
            <div className="results">
                <div className="title">
                    <h2>Reservations</h2>
                    <h3>Search Results {searchData?.numofdays} days for  {formData.airportName} from {formData.startDate} to {formData.endDate}</h3>
                </div>

                { isFormSubmitted ? <div className="cards">
                    { searchData?.parkings?.map((item, idx) => {
                         const createMarkup = (content) => ({ __html: (content ?? '').replace(/<br\s*\/?>/g, '\n') });

                        return (
                            <div className="card" key={idx}>
                                <div className="left-note">
                                    <h3>{item.parkinglotname}</h3>
                                  {item?.holidaydaysinavg?.map((holiday, ix)=>{
                                       return (<p className="head-note" key={ix}>Please note: Holiday rate of ${holiday.dailyrate} per day in affect from {Constants.formatDate(holiday.startdate) } to {Constants.formatDate(holiday.enddate) }</p>)
                                    })} 
                                    <div className="general-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.shortsecuritystatement)} />
                                    </div>
                                    <div className="complementary-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.transportation)} />
                                    </div>
                                </div>
                                <div className="price">
                                <div className="top-price">
                                    <div className="ticketing">
                                        <h4>TYPE</h4>
                                        <p>{item.parkingtype}</p>
                                    </div>
                                    <div className="ticketing">
                                        <h4>AVG. RATE</h4>
                                        <p>${item.avgrate.toFixed(2)}</p>
                                    </div>
                                </div>
                                 
                               {mainResvId===0 && <div className="mid-price">
                                    <p>Sub-Total = ${item.subtotal.toFixed(2)}</p>
                                    <p>Service Fee = ${item.servicefee.toFixed(2)}</p>
                                    <p>Sales Tax = ${item.tax.toFixed(2)}</p>
                                    {item.freedaysavings === 0 ? '' : <p>Free Day Savings = ${item.freedaysavings.toFixed(2)}</p> }
                                    <h3>Amount Total = ${item.totalamt.toFixed(2)}</h3>
                                </div>} 
                              {mainResvId===0 && <div className="total-price">
                                    <h3>Amount Total = ${item.totalamt.toFixed(2)}</h3>
                                    <p>Due at Lot = ${item.dueatlot.toFixed(2)}</p>
                                </div>}

                                {mainResvId>0 && <div className="mid-price">
                                <div> The Change Status value {item?.oldstatus} already paid {item?.alreadypaid} calculated payment {item?.onlinepay} already due {item?.alreadydue} calculated due {item?.dueatlot}</div>
                                    <p>Sub-Total  = ${item.subtotal.toFixed(2)}</p>
                                    <p>Service Fee  = ${item.servicefee.toFixed(2)}</p>
                                    <p>Sales Tax = ${item.tax.toFixed(2)}</p>
                                    {item.freedaysavings === 0 ? '' : <p>Free Day Savings = ${item.freedaysavings.toFixed(2)}</p> }
                                    <h3>Amount Total = ${item.totalamt.toFixed(2)}</h3>
                                </div>} 
                              {mainResvId>0 && <div className="total-price">
                                    {item?.oldstatus==='changelot' && <p>To Pay online ${item.totalamt.toFixed(2)} - ${Constants.formatCurrency(item?.alreadypaid) } Already Paid  </p>}
                                    {item?.oldstatus==='extend' && <p>You previously paid for ({searchData?.numofdays- searchData?.currentdays})days @ ${Constants.formatCurrency(item?.alreadypaid)}. You are adding {searchData?.currentdays}day(s) at the avg rate specified above. </p>}
                                    {item?.oldstatus==='short_trip' && <p>To Pay online ${item.totalamt.toFixed(2)} - ${Constants.formatCurrency(item?.alreadypaid) } Already Paid  </p>}
                                    {item?.oldstatus==='shift' && <p>To Pay online ${item.totalamt.toFixed(2)} - ${Constants.formatCurrency(item?.alreadypaid) } Already Paid  </p>}
                                   
                                    <h3>Amount Total = ${item.finalpay.toFixed(2)}</h3>
                                    <p>Due at Lot = ${item.dueatlot.toFixed(2)}</p>
                                </div>}  


                                <div className="btn">
                                    <Link to={mainResvId===0? `/admin/checkout/${item.parkinglotid}`:`/admin/checkout/${item.parkinglotid}/${mainResvId}` }>
                                        <button disabled={item.booked === true || item.blocked === true}  className={item.booked === true || item.blocked === true ? 'cancel-btn' : 'reserve-btn'}>
                                            {item.booked === true || item.blocked === true ? 'UNAVAILABLE' : 'RESERVE'}
                                        </button>
                                    </Link>
                                </div>
                                </div>
                            </div>
                        )
                    })}
                </div> : ''}
                
            </div>
        </div>
    </div>
  )
}

export default AdminSearchResult