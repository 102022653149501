import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import SignUpImg from '../../assets/signup.png';
import Loading from '../../components/Loading';
import '../../styles/User/signup.css';
import { ShareContext } from '../../context/ShareContext';
import ReactGA from 'react-ga';
import * as Constants from '../../constants';

function Signup() {
    const states = ['AB', 'AK', 'AL', 'AR', 'AZ', 'BC', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MB', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NB', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NL', 'NM', 'NS', 'NT', 'NU', 'NV', 'NY', 'OH', 'OK', 'ON', 'OR', 'PA', 'PE', 'PR', 'QC', 'RI', 'SC', 'SD', 'SK', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY', 'YT'];

    
  Constants.seo({title:'Airport Parking Reservations | Register',
  metaDescription:'Airport Parking Reservations. Quick And Easy Long Term Offsite Airport Parking Reservations. 24-hour Video Surveillance. Convenient Shuttle Service.'})

    const [passwordTwoValid, setPasswordTwoValid] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [loading, setLoading]=useState(false);

    const { setToken, setCustomerDetails} = useContext(ShareContext);

    const [signupData, setSignupData] = useState({
        "firstname": '',
        "lastname": '',
        "mi": '',
        "email": '',
        "password": '',
        "password2": '',
        "phone": '',
        "zipcode": '',
        "address1": '',
        "address2": '',
        "city": '',
        "state": '',
    })

    const signupChange = (e) => {
        setSignupData({...signupData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)

        if (signupData.password !== signupData.password2){
            setPasswordTwoValid(true);
        } else {
            setPasswordTwoValid(false)
        }

        if (!passwordTwoValid) {
            try {
                const resp = await fetch(`${Constants.apiUrl}/reservation/customer`, {
                  method: 'POST',
                  body: JSON.stringify(signupData),
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': '',
                  },
                });
                
                const responseData = await resp.json();
                console.log(responseData);
                setLoading(false)
      
                if (responseData.code === 0) {
                    setIsSuccessful(true)
                  
                  const { token } = responseData;
                  setCustomerDetails(signupData); // to holder user information
                  setToken(token)
      
                  localStorage.setItem('accessToken', token);
      
                }else {
                  alert(responseData.status);
                }
              } catch (error) {
                console.error(error);
              }
        }
    }
    ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className='sign-up'>
        <img src={SignUpImg} alt="Airport" loading='lazy' />
        <div className="signin-form">
            <div className="container">
                <div className="text">
                    <p className='welcome'>Welcome to <span>Airport Parking Inc</span></p>
                    <h2 className="bigText">Register</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="first-name">First Name</label>
                            <input type="text" id='first-name' name='firstname' value={signupData.firstname} onChange={signupChange} required/>
                        </div>
                        <div className="input">
                            <label htmlFor="last-name">Last Name</label>
                            <input type="text" id='last-name' name='lastname' value={signupData.lastname} onChange={signupChange} required/>
                        </div>
                    </div>
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="middle-name">Middle Initial</label>
                            <input type="text" id='middle-name' name='mi' value={signupData.mi} onChange={signupChange}/>
                        </div>
                        <div className= "input">
                            <label htmlFor="signup-email">Your Email</label>
                            <input type="email" id='signup-email' name='email' value={signupData.email} onChange={signupChange} />
                        </div>
                    </div>
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="create-password">Create your Password</label>
                            <input type="password" id='create-password' name='password' value={signupData.password} onChange={signupChange} />
                        </div>
                        <div className={passwordTwoValid ? "input error" : "input"}>
                            <label htmlFor="confirm-password">Confirm Password</label>
                            <input type="password" id='confirm-password' name='password2' required value={signupData.password2} onChange={signupChange}/>
                            <p className="error">Passwords don't match</p>
                        </div>
                    </div>
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="phone">Phone Number</label>
                            <input type="tel" id='phone' name='phone' value={signupData.phone} onChange={signupChange} required />
                        </div>
                        <div className="input">
                            <label htmlFor="zip-code">ZIP Code</label>
                            <input type="text" id='zip-code' name='zipcode' value={signupData.zipcode} onChange={signupChange} required/>
                        </div>
                    </div>
                    <div className="input">
                        <label htmlFor="address-one">Address</label>
                        <input type="text" id='address-one' name='address1' value={signupData.address1} onChange={signupChange} required/>
                    </div>
                    <div className="input">
                        <label htmlFor="address-two">Address (2)</label>
                        <input type="text" id='address-two' name='address2' value={signupData.address2} onChange={signupChange}/>
                    </div>
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="city">City</label>
                            <input type="text" id='city' name='city' value={signupData.city} onChange={signupChange} required/>
                        </div>
                        <div className="input">
                            <label htmlFor="states">State</label>
                            <select name="state" id="states" value={signupData.state} onChange={signupChange} required>
                                { states.map((state, idx) => {
                                    return(
                                        <option key={idx} value={state}>{state}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="submit">
                        <button type='submit' className='register-btn'>Register</button>
                        <p>Already have an account? <Link to='/reservation/login'>Log In</Link></p>
                    </div>
                </form>
            </div>
        </div>
        {loading && <div className={loading ? 'congrats show' : 'congrats'}>
                <Loading message='Registering' />
              </div>}
      {isSuccessful && <div className={   "message show"}>
            <div className="container">
                <h3>Congratulations on your Registration</h3>
                <p>Please click the button below to login to your account</p>
                <Link to='/reservation/login'><button>Login</button></Link>
            </div>
        </div>}  
    </div>
  )
}

export default Signup