import React, { useContext,useState, useEffect } from 'react'
import TopBar from '../../components/User/TopBar'
import '../../styles/User/receipt.css'
import { useParams } from 'react-router-dom'
import { ShareContext } from '../../context/ShareContext';
import * as Constants from '../../constants';
import LoadingImg from '../../assets/loading.gif';
import Expired from '../../components/Expired';
import ReactGA from 'react-ga';

function Receipt() {
    const {reservationId} = useParams() //this represent reservation

    const { token, searchData } = useContext(ShareContext);
    const [numofdays, setNumofdays]=useState(0);
    const [loadingStatus, setLoadingStatus] =useState(true);
    const [rdata, setReceiptData] =useState({});
  const [isExpired, setIsExpired] = useState(false);


    useEffect(() => {
        setLoadingStatus(true);
        fetch(`${Constants.apiUrl}/reservation/receipt/${reservationId}`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Authorization': token,
          },
        })
          .then((resp) => resp.json())
          .then((data) => {
            
            console.log(data);
            if(data.code==0){
                setReceiptData(data);
                setNumofdays(data.numofdays);
            }
            else if(data.code==-10){
                //logOut(); //possible the token is not available via context
                setIsExpired(true)
            }
            setLoadingStatus(false);
          })
          .catch((error) => {
            console.log(error);
            
          });
      }, [reservationId]);

      ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className='receipt-body'>
        <TopBar />
        { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}

        {loadingStatus && <div className={ loadingStatus ? "congrats show" : "congrats"}>
                <div className="container">
                    <h2>Loading....</h2>
                    <img src={LoadingImg} width='200px' alt="Loading" />
                </div>
            </div>}
        {!loadingStatus &&  <div className="body">
            <div className="heading">
                <h1 className='title'>Reservation Receipt</h1>
                {/* <p>A copy of this re-print will be sent to the customer</p> */}
            </div>
            <div className="parking-lot-summary">
                <p className="summary"> {rdata.parkinglot.parkinglotname} requires you to present your receipt to receive your quoted price and credit
                 for your online payment. Please note that the service fee is not refunded to you by the parking lot. If you do not present your receipt to 
                 {rdata.parkinglot.parkinglotname}, you will not get credit for your online payment and this online payment will not be refunded at a later date.</p>
            </div>
            <div className="receipt">
            <div className="barcode">
                  {(rdata.parkinglot.generatebarcode == "Yes" && rdata.parkinglot.barcodetype != "qrcode") && 
                  <img src={"http://www.barcodesinc.com/generator/image.php?code="+rdata.reservation.barcodenum+"&style=196&type=C128C&width=150&height=80&xres=1&font=3"} />
                }
                {(rdata.parkinglot.isAmano) &&  
                    <img alt="" style={{height:100, width:100, alignSelf:'center', margin:20}}  src={`https://chart.googleapis.com/chart?cht=qr&chl=AW${rdata.reservation.transactionid}&chs=160x160&chld=L|0`} />
                }
                </div>

                <div className="row">
                    <p className="title">Reservation ID </p>
                    <p className="answer">AW{rdata.reservation.transactionid}</p>
                </div>
                <div className="row">
                    <p className="title">Customer Name</p>
                    <p className="answer">{rdata.customer.firstname + ' ' + rdata.customer.lastname}</p>
                </div>
                <div className="row">
                    <p className="title">Lot</p>
                    <p className="answer">{rdata.parkinglot.parkinglotname}</p>
                </div>
                <div className="row">
                    <p className="title">Number of cars</p>
                    <p className="answer">{rdata.reservation.carCount}</p>
                </div>
               
                <div className="row">
                    <p className="title">Arrival</p>
                    <p className="answer">{Constants.formatDateTime(rdata.reservation.arrivaldate) }</p>
                </div>
                <div className="row">
                    <p className="title">Departure</p>
                    <p className="answer">{Constants.formatDateTime(rdata.reservation.returndate) }</p>
                </div>
                <div className="row">
                    <p className="title">Car Details</p>
                    <p className="answer">{rdata.reservation.carDetails}</p>
                </div>
                <div className="row">
                    <p className="title">Paid Online</p>
                    <p className="answer">${Constants.formatCurrency(rdata.reservation.totalpaid) }</p>
                </div>
                <div className="row">
                    <p className="title">Due At Lot</p>
                    <p className="answer">${Constants.formatCurrency(rdata.reservation.payatlot) }</p>
                </div>
                <div className="row">
                    <p className="title">Total</p>
                    <p className="answer">${Constants.formatCurrency(rdata.reservation.totalamount)}</p>
                </div>
                <div className="row">
                    <p className="title">Tax</p>
                    <p className="answer">${Constants.formatCurrency(rdata.reservation.tax)}</p>
                </div>
                <div className="row">
                    <p className="title">Person Parking</p>
                    <p className="answer">{rdata.reservation.personparking}</p>
                </div>
                <div className="row">
                    <p className="title">Number of Persons</p>
                    <p className="answer">{rdata.reservation.numofpersons}</p>
                </div>
                <div className="row">
                    <p className="title">Plate Number</p>
                    <p className="answer">{rdata.reservation.plateNumber}</p>
                </div>
                <div className="row">
                    <p className="title">Name of Airline or Cruise Ship</p>
                    <p className="answer">{rdata.reservation.vesselname}</p>
                </div>
                <div className="row">
                    <p className="title">Customer Service Number</p>
                    <p className="answer">{rdata.parkinglot.phone} (for parking reservation)</p>
                </div>
                <div className="row">
                    <p className="title">Parking Type</p>
                    <p className="answer">{rdata.parkinglot.parkingtype}</p>
                </div>
                <div className="row">
                    <p className="title">Lot Address</p>
                    <p className="answer">{rdata.parkinglot.address1}</p>
                </div>
                <div className="row">
                    <p className="title">Hotel/Parking Lot Number:</p>
                    <p className="answer">{rdata.parkinglot.parkinglotphone} <br />Please call this numberfor direction and shuttle</p>
                </div>

                <p className="note">This page or your email confirmation <br />
                    MUST BE PRESENTED
                    to {rdata.parkinglot.parkinglotname}
                    AT THE TIMEOF PAYMENT
                    along with one form of personal identification to validate this offer and receive credit for the equivalent of {numofdays} days paid. If you are unable to print your confirmation, please notify us by
                    email
                    (mailto:services@airportparkinginc.com)
                    .We will arrange to fax or mail you acopy. To receive a copy by mail your departure date must be minimum of 5 business days from today's date.
                </p>
            </div>
            <div className="parking-personnel">
                <h3>For Parking-Personnel Only</h3>
                <div className="receipt">
                    <div className="row">
                        <p className="title">Reservation ID</p>
                        <p className="answer">AW{rdata.reservation.transactionid}</p>
                    </div>
                    <div className="row">
                        <p className="title">Cashier</p>
                        <p className="answer">The customer is to be credited with {numofdays} days of parking. DO NOT give credit for the additional ${rdata.parkinglot.servicefee} service fee paid online by the customer.</p>
                    </div>
                    <div className="row">
                        <p className="title">Proof of Purchase</p>
                        <p className="answer">{rdata.parkinglot.parkinglotname} requires you to present your receipt to receive your quoted price and credit for your online payment. Please note that the service fee is not refunded to you by the parking lot. If you do not present your receipt to {rdata.parkinglot.parkinglotname}, you will not get credit foryour online payment and this online payment will not be refunded at a later date.</p>
                    </div>
                    <div className="row">
                        <p className="title">Parking Information</p>
                        <p className="answer">When you arrive at the hotel parking lot
                            please go inside before parking. Please have your Reservation Receipt, ParkingTag, and Vehicle License Plate Number ready. Keep a copy of the reservation receipt during travel and for the hotel. After signing in proceed to gate scanbar code and take ticket. Must park in parking LOT B. Parking in guest parkingis subject to be towed. When you return for your vehicle, please sign out atthe Front Desk as well. You will need to present your parking ticket at sign out .
                            When you arrive back at the hotel parking lot/garage from the airport shuttle busupon return
                            When you return for your vehicle, please sign out at the Front Desk as well.You will need to present your parking ticket at sign out .
                            NOTE:
                            If you will be returning past the time of your reservation, you must go online and add additional days and time to reservation or call customer service to updateyour new time. You will be required to reprint the new barcode on reservation.
                        </p>
                    </div>
                   {rdata.parkinglot.generatebarcode == "Yes" && <div className="row">
                    <div class="row">
                            <div class="col-md-10"><strong>When you arrive at the hotel parking lot</strong></div>
                            <div class="col-md-12">
                                <ul>
                                    <li>please go inside before parking. Please have your Reservation Receipt, Parking Tag, and Vehicle License Plate Number ready. Keep a copy of the reservation receipt during travel and for the hotel. After signing in proceed to gate scan bar code and take ticket. Must park in parking LOT B. Parking in guest parking is subject to be towed.​ ​When you return for your vehicle, please sign out at the Front Desk as well. You will need to present your parking ticket at sign out.​</li>
                                </ul>

                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12"><strong>When you arrive back at the hotel parking lot/garage from the airport shuttle bus upon return</strong></div>
                            <div class="col-md-12">
                                <ul>
                                    <li>When you return for your vehicle, please sign out at the Front Desk as well. You will need to present your parking ticket at sign out​.​</li>
                                </ul>
                                <strong>NOTE:</strong> If you will be returning past the time of your reservation, you must go online and add additional days and time
                                to reservation or call customer service to update your new time. You will be required to reprint the new barcode on reservation.
                            </div>
                        </div>
                        </div>} 
                    <div className="row">
                        <p className="title">Parking Rate</p>
                       {/*  <p className="answer" dangerouslySetInnerHTML={{__html:  rdata.parkinglot.parkingrateinfo}}></p> */}
                        <p className="answer" > Your actual price may be higher or lower, based on the actual length of time your vehicle is parked at
                                {rdata.parkinglot.parkinglotname}.  This offer may not be combined with any other discount or promotion.</p>
                    </div>
                    <div className="row">
                        <p className="title">Transportation</p>
                        <p className="answer" dangerouslySetInnerHTML={{__html:  rdata.parkinglot.transportation}}>
                        </p>
                    </div>
                    <div className="row">
                        <p className="title">Arrival Information</p>
                        <p className="answer" dangerouslySetInnerHTML={{__html:  rdata.parkinglot.arrivalinfo}}></p>
                    </div>
                    <div className="row">
                        <p className="title">Services</p>
                        <p className="answer">This reservation is valid for check-in on {Constants.formatDateTime(rdata.reservation.arrivaldate) }. If your itinerary changes, please contact us. If your trip is canceled, you must provide us with at least 48 hours notice before your departure date to receive a refund of the amount you paid online.
`                       If you need to cancel, please logon to your account and issue a cancellation request.</p>
                    </div>
                    <div className="row">
                        <p className="title">Direction</p>
                        <p className="answer" dangerouslySetInnerHTML={{__html:  rdata.parkinglot.direction}}></p>
                    </div>
                    <div className="row">
                        <p className="title">Important Notice</p>
                        <p className="answer">This reservation is for airport parking ONLY and is not to be applied towards parking for a hotel stay or any hotel-related event. Furthermore, this airport parkingreservation is for parking and retrieving your vehicle one time and one time only. In
                        Dell
                        dellnelson
                        and out privileges are not granted for airport parking reservations.</p>
                    </div>
                    <div className="row">
                        <p className="title">Folllow us on Twitter</p>
                        <p className="answer">Tweet (http://twitter.com/share)</p>
                    </div>
                    
                </div>
            </div>
            <button className='print' onClick={() => window.print()}>Print</button>
        </div>}
    </div>
  )
}

export default Receipt