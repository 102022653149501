import React, { useState, useContext } from 'react'
import '../../styles/User/login.css'
import { Link } from 'react-router-dom';
import LoginImg from '../../assets/login.jpg';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom';
import UserProfile from './UserProfile';
import Loading from '../../components/Loading';
import * as Constants from '../../constants';
import ReactGA from 'react-ga';

function Login() {

  Constants.seo({title:'Airport Parking Reservations | Login',
  metaDescription:'Airport Parking Reservations. Quick And Easy Long Term Offsite Airport Parking Reservations. 24-hour Video Surveillance. Convenient Shuttle Service.'})
  
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorEmailMsg, setErrorEmailMsg] = useState('Please input your e-mail');
    const [errorMessage, setErrorMessage] = useState('');
    const { setIsUserLoggedIn, setUserData, setToken, isUserLoggedIn} = useContext(ShareContext);
    const [loading, setLoading]=useState(false);
    const [loginWith, setLoginWith]=useState("password");
    const [lastname,setLastname] =useState('');

    const isEmailValid = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };

    const validateForm = () => {
        let isValid = true;
      
        if (email.trim() === '' || !isEmailValid(email)) {
          setErrorEmail(true);
          setErrorEmailMsg(!email ? 'Please enter an email address' : 'Please enter a valid email address');
          isValid = false;
        } else {
          setErrorEmail(false);
          setErrorEmailMsg('');
        }
      
        // if (password.trim() === '' || lastname.trim() === '') {
        //   setErrorPassword(true);
        //   isValid = false;
        // } else {
        //   setErrorPassword(false);
        // }
      
        return isValid;
      };
  
    const navigate = useNavigate();
  
    const submitForm = async (e) => {
      e.preventDefault();
      setLoading(true);
    
      setErrorMessage('');
      var loginobj={};
        if(loginWith=='password')
          loginobj ={ email, password ,lastname:''};
        else
        loginobj ={ email, lastname,password:'' };

      if (validateForm()) {
        try {
          console.log('login object');
          console.log(JSON.stringify(loginobj));
          const resp = await fetch(`${Constants.apiUrl}/reservation/login`, {
            method: 'POST',
            body: JSON.stringify(loginobj),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': '',
            },
          });
          const responseData = await resp.json();
          setLoading(false);
          console.log(responseData);
          if (responseData.code === 0) {
            setIsUserLoggedIn(true);
            navigate('/reservation');
            setUserData(responseData);
            
            const { c } = responseData

            localStorage.setItem('customerDetails', JSON.stringify(c))
            
            const { token } = responseData;

            setToken(token)

            localStorage.setItem('accessToken', token);
            localStorage.setItem('tokenType', 'customer');

          }else {
            setErrorMessage('Username or password or lastname is incorrect');
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          setErrorMessage('Failed to Login');
        }
      }
    };
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
    
      <div className='login-page'>
        <img src={LoginImg} alt="Car Park" className='log-in-img' loading='lazy' />
        <div className="login-form">
          <div className="container">
            <div className="text">
              <p className='welcome'>Welcome to <span>Airport Parking Inc</span></p>
              <h2 className="bigText">Log In</h2>
              <p className='error'>{errorMessage}</p>
            </div>
            <form>
              <div className={errorEmail ? 'input error' : 'input'}>
                <div className="labels">
                  <label htmlFor="log-in-email">Email</label>
                  <p className="error">{errorEmailMsg}</p>
                </div>
                <input type="email" id='log-in-email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>
              <div className={errorPassword ? 'input error' : 'input'}>
                <div className="labels">
                  <label htmlFor="log-in-password"> {loginWith=='password'?'Password':'Lastname'} </label>
                  <p className="error">Please Input {loginWith=='password'?'Password':'Lastname'} </p>
                </div>
                 

               {loginWith=='password' && <input type="password" id='log-in-password' name='password' value={password} onChange={(e) => setPassword(e.target.value)} required />}
                {loginWith=='lastname' && <input type="text" id='log-in-email' name='email' value={lastname} onChange={(e) => setLastname(e.target.value)} required />}
                <Link to='/home/forgetpassword'>Forgot Password</Link> <Link onClick={()=> setLoginWith(loginWith=='password'?'lastname':'password')}>Login with Lastname</Link>
              </div>
              <div>    </div>
              <div className="submit">
              {loading && <div className={loading ? 'congrats show' : 'congrats'}>
                <Loading message='Logging In' />
              </div>}
              {!loading && <button type='submit' className='login-btn' onClick={submitForm}>Log In</button>}  
                <p>Don't have an account? <Link to='/home/register'>Register</Link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
      
    );
  }
  

export default Login