export const articles = [
    {
        id: 1,
        title: 'Miscallenous',
        articles: [
            {
                id: 1,
                name: 'Save Money with Cheap Airport Parking',
            },
            {
                id: 2,
                name: 'Quick and Easy Airport Parking Reservations',
            },
            {
                id: 3,
                name: 'Using Airport Parking Coupons to Save Money',
            },
            {
                id: 4,
                name: 'Looking for Thrifty Airport Parking?',
            },
        ]
    },
    {
        id: 2,
        title: 'Atlanta(ATL)',
        articles: [
            {
                name: 'Your Internet Search for "Parking At Atlanta Airport"',
                id: 1
            },
            {
                name: 'Want Quick Airport Parking? Atlanta Offers Options',
                id: 2
            },
            {
                name: 'Flying Out of the Atlanta Airport? Parking Rates May Be Higher Than You Think',
                id: 3
            },
            {
                name: 'Your Best Choice for Atlanta Airport Park and Fly',
                id: 4
            }
        ]
    },
    {
        id: 3,
        title: 'Baltimore (BWI)',
        articles: [
            {
                name: 'Overcoming the Challenge of Parking at BWI',
                id: 1
            },
            {
                name: 'Finding Convenient BWI Airport Parking',
                id: 2
            },
            {
                name: 'BWI Long Term Parking For Less',
                id: 3
            }
        ]
    },
    {
        id: 4,
        title: 'Chicago (ORD)',
        articles: [
            {
                name: "Looking for the O'Hare Airport Code",
                id: 1
            },
            {
                name: "Is O'Hare Long Term Parking the Only Way to Park at the Airport?",
                id: 2
            },
            {
                name: 'Airport Code ORD Parking Made Easy!',
                id: 3
            },
            {
                name: "Finding Parking at O'Hare, the World's Busiest Airport",
                id: 4
            }
        ]
    },
    {
        id: 5,
        title: 'Boston Logan (BOS)',
        articles: [
            {
                name: 'Boston Logan Airport Parking: Reserve A Spot with Airport Parking Inc.',
                id: 1
            },
            {
                name: 'Secure Your Cheap Boston Airport Parking Rates from Airport Parking Inc',
                id: 2
            }
        ]
    },
    {
        id: 6,
        title: 'Dallas (DFW)',
        articles: [
            {
                name: 'Using DFW Airport Shuttle Service',
                id: 1
            },
            {
                name: 'Safe, Secure & Affordable DFW Airport Parking',
                id: 2
            },
            {
                name: 'Your #1 Source For Cheap Dallas Fort Worth Airport Parking Rates',
                id: 3
            },
            {
                name: 'Look Here for Long-Term Dallas Airport Parking Rates',
                id: 4
            }
        ]
    },
    {
        id: 7,
        title: 'Detroit (DTW)',
        articles: [
            {
                name: 'Making Detroit Airport Parking Advance Reservations',
                id: 1
            }
        ]
    },
    {
        id: 8,
        title: 'Fort Lauderdale (FLL)',
        articles: [
            {
                name: 'FLL Parking and You',
                id: 1
            },
            {
                name: 'FLL Airport Parking Issues and Tips',
                id: 2
            },
            {
                name: 'The Challenges of Fort Lauderdale Airport Parking',
                id: 3
            },
            {
                name: 'Perfect Port Everglades Parking',
                id: 4
            },
            {
                name: 'Get Cheap FLL Airport Parking Rates',
                id: 5
            }
        ]
    },
    {
        id: 9,
        title: 'Jacksonville (JAX)',
        articles: [
            {
                name: 'Jacksonville Airport Parking and You!',
                id: 1
            }
        ]
    },
    {
        id: 10,
        title: 'Los Angeles (LAX)',
        articles: [
            {
                name: 'Looking For Cheap Parking? LAX Options That Save You Money',
                id: 1
            },
            {
                name: 'Cheap Airport Parking: LAX and Beyond',
                id: 2
            },
            {
                name: 'Hassle-Free Airport Parking, LAX Area, Quick Reservations.',
                id: 3
            },
            {
                name: "Easy Airport Parking. LAX Doesn't Offer It.",
                id: 4
            },
            {
                name: 'Finding Long Term Parking: LAX Has Options',
                id: 5
            },
            {
                name: 'Should You Use LAX Parking Coupons?',
                id: 6
            },
            {
                name: 'Looking for Cheap LAX Parking?',
                id: 7
            },
            {
                name: 'How To Find Cheap Airport Parking In LAX',
                id: 8
            },
            {
                name: 'How to find Cheap Airport Parking Rates',
                id: 9
            },
            {
                name: 'Get the Best LAX Airport Parking',
                id: 10
            },
            {
                name: 'Low Cost Los Angeles Airport Parking from Airport Parking Inc',
                id: 11
            },
            {
                name: 'Cheap LAX Airport Parking Rates from Airport Parking Inc',
                id: 12
            }
        ]
    },
    {
        id: 11,
        title: 'LaGuardia',
        articles: [
            {
                name: 'Keep Your Car Secure with Cheap LGA Airport Parking Rates from Airport Parking Inc.',
                id: 1
            }
        ]
    },
    {
        id: 12,
        title: 'Miami (MIA)',
        articles: [
            {
                name: 'Best Long-Term Miami Airport Parking Rates',
                id: 1
            },
            {
                name: 'Looking for the Best Long-Term MIA Airport Parking Rates?',
                id: 2
            }
        ]
    },
    {
        id: 13,
        title: 'Nashville (BNA)',
        articles: [
            {
                name: 'Avoid the Hassle of Nashville Airport Parking',
                id: 1
            }
        ]
    },
    {
        id: 14,
        title: 'Newark (EWR)',
        articles: [
            {
                name: 'Find Cheap Newark Airport Parking Rates',
                id: 1
            },
            {
                name: 'Trustworthy and Cheap Parking - Newark Airport Parking Rates',
                id: 2
            }
        ]
    },
    {
        id: 15,
        title: 'New York (JFK)',
        articles: [
            {
                name: 'Finding Inexpensive Parking at JFK',
                id: 1
            },
            {
                name: 'Find Remarkably Cheap JFK Airport Parking Rates',
                id: 2
            },
            {
                name: 'JFK airport Long-Term Parking Options',
                id: 3
            }
        ]
    },
    {
        id: 16,
        title: 'Orlando (MCO)',
        articles: [
            {
                name: 'Orlando International Airport, AKA MCO, Parking Options',
                id: 1
            },
            {
                name: 'What Airport Uses the Airport Code MCO?',
                id: 2
            },
            {
                name: 'Take Advantage of These Cheap Orlando Airport Parking Rates',
                id: 3
            },
            {
                name: 'We Offer the Best Orlando Airport Long Term Parking',
                id: 4
            },
            {
                name: 'Let Us Help You Find Cheap Parking, Orlando Airport Travelers',
                id: 5
            },
            {
                name: 'Learn About Our Cheap MCO Airport Parking Rates with Airport Parking Inc',
                id: 6
            }
        ]
    },
    {
        id: 17,
        title: 'Phoenix (PHX)',
        articles: [
            {
                name: 'Phoenix Airport Parking: There Must Be a Better Way!',
                id: 1
            }
        ]
    },
    {
        id: 18,
        title: 'Providence (PVD)',
        articles: [
            {
                name: 'Parking Your Vehicle at the Providence Airport? Parking Rates May Make You Think Twice.',
                id: 1
            }
        ]
    },
    {
        id: 19,
        title: 'Seattle (SEA)',
        articles: [
            {
                name: 'Finding Smart Seattle Airport Parking',
                id: 1
            },
            {
                name: 'If You Need Airport Parking, SeaTac Onsite Lots May Not Be Best',
                id: 2
            }
        ]
    },
    {
        id: 20,
        title: 'St. Louis (STL)',
        articles: [
            {
                name: 'Looking for Cheap Lambert Airport Parking?',
                id: 1
            }
        ]
    },
]