import React from 'react'
import '../../styles/User/privacy.css'
import TopBar from '../../components/User/TopBar'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga';

function PrivacyPolicy() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className='privacy-policy'>
        <TopBar />
        <div className="main-policy">
            <h1>Privacy Policy Statement</h1>
            <div className="policy">
                <h2>The Postal address for AirportParkingInc.com is:</h2>
                <div className="policy-info">
                    <p className='address'> AirportParkingInc.com. <br />
                        PO BOX 690815, <br />
                        Orlando, FL 32869, U.S.A.
                    </p>
                    <p className='web'>
                        Our web site is located at: <Link to='/'>http://new.airportparkinginc.com</Link> <br />
                        If you have any questions or suggestions regarding our privacy policy, please contact us by clicking this link: <Link to='/contact-us'>Contact Us</Link> <br />
                        AirportParkingInc.com. knows that you care about how your personal information is collected, stored, shared, and used. We value your trust and will work hard to ensure that any personally identifiable information in our possession is handled carefully and professionally. By visiting AirportParkingInc.com you are accepting the Privacy Policy described herein.
                    </p>
                </div>
                <div className="policy-info">
                    <h2>Our Commitment To Privacy</h2>
                    <p>Your privacy is important to us. To better protect your privacy we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used. To make this notice easy to find, we make it available on our homepage and at every point where personally identifiable information may be requested.</p>
                </div>
                <div className="policy-info">
                    <h2>The Information We Collect:</h2>
                    <p>This notice applies to all information collected or submitted on the AirportParkingInc.com website. On some pages, you can order products, make requests, and register to receive materials. The types of personal information collected at these pages are:</p>
                    <ul>
                        <li>Name</li>
                        <li>Address</li>
                        <li>E-mail Address</li>
                        <li>Phone Number</li>
                        <li>Credit/Debit Card Information</li>
                    </ul>
                </div>
                <div className="policy-info">
                    <h2>The Way We Use Information:</h2>
                    <p> We use the information you provide about yourself when placing a reservation only to        complete that reservation. We do not share this information with outside parties except to the extent necessary to complete that order. <br />
                        We use return email addresses to answer the email we receive. Such addresses are not used for any other purpose and are not shared with outside parties. <br />
                        You can register with our website if you would like to receive our newsletteras well as updates on our crrent rental specials. Information you submit on our website will not be used for this purpose unless you fill out the registration form. <br />
                        We use non-identifying and aggregate information to better design our website and to share with advertisers. For example, we may tell an advertiser that X number of individuals visited a certain area on our website, or that Y number of men and Z number of women filled out our registration form, but we would not disclose anything that could be used to identify those individuals. <br />
                        Finally, we never use or share the personally identifiable information provided to us online in ways unrelated to the ones described above without also providing you an opportunity to opt-out or otherwise prohibit such unrelated uses. 
                    </p>
                </div>
                <div className="policy-info">
                    <h2>Our Commitment To Data Security</h2>
                    <p>To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.</p>
                </div>
                <div className="policy-info">
                    <h2>Opting Out of Future Communication</h2>
                    <p>From time to time, AirportParkingInc.com may communicate with existing customer via email, phone calls, or direct mail on promotions, availability of new products or features on our website, and any other relevant business matter. To opt out of such future communication that might be initiated by Airport Parking Inc, please logon to your account and click the opt-out link under your customer profile to fill and submit the opt-out form. You may also send email to <a href="mailto:support@airportparkinginc.com">support@airportparkinginc.com</a> with the subject "Opt Out" and specify your firstname, lastname, email address and the channel you wish to opt out of (i.e., email, phone calls and/or direct mail) in the body of the email.</p>
                </div>
                <div className="policy-info">
                    <h1>Changes to Our Privacy Policy:</h1>
                    <p>AirportParkingInc.com reserves the right to modify or amend this Privacy Policy at any time and for any reason. If there are material changes to this statement or in how AirportParkingInc.com will use personally identifiable information, AirportParkingInc.com will post notice on the AirportParkingInc.com homepage at <Link to='/'>new.airportparkinginc.com</Link> prior to implementing the change. The amended Terms shall automatically become effective immediately after they are posted. Your continued use of the site or AirportParkingInc.com services after the posting of the amended Terms on the Site constitutes your affirmative:
                    <ul>
                        <li>(a) acknowledgement of the terms and its modifications and</li>
                        <li>(b) agreement to abide and be bound by the terms, as amended. If you have additional questions about this Policy, please contact us at <a href="mailto:support@airportparkinginc.com">support@airportparkinginc.com</a>.</li>
                    </ul>
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PrivacyPolicy