import React, { useState, useContext, useEffect } from 'react';
import '../../styles/Admin/manageairport.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faRotateRight, faSearch, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter} from '@fortawesome/free-solid-svg-icons'
import { ShareContext } from '../../context/ShareContext'
import { states } from '../../data/userstates'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom'
import Loading from '../../components/Loading'
import LoadingImg from '../../assets/loading.gif';
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired'
import ApiAlert from '../../components/ApiAlert'

function SingleParkinglot() {
    const {parkinglotid}=useParams();
    const { airportList, isSidebarClosed, adminToken } = useContext(ShareContext);

    // console.log(airportList);
    const [loading, setLoading] = useState(false)
    const [loadMsg, setLoadMsg] = useState('')

    const [view, setView]= useState('new');
    const [expandForm, setExpandForm] = useState(false)
    const [openCreate, setOpenCreate] = useState(false);
    const [editData, setEditData] = useState({});
    const [openedEdit, setOpenedEdit] = useState(false)
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false)
    const [isNew, setIsNew] = useState(false);
    const [isLoading, setIsLoading]= useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [alert, setAlert]=useState({ open:false});
    const [airportFormData, setAirportFormData] = useState({
        "airportcode": "",
        "airportshortname": "",
        "airportlongname": "",
        "airportcity": "",
        "airportstate": "",
        "airportaddress": "",
        "airportzip": ""
    })
    const [searchValue, setSearchValue] = useState('');
    const [filterCity, setFilterCity] = useState('')

    const [airportState, setAirportState] = useState(airportList)
    const [activelot, setActivelot] =useState({});

   

    const cities = airportState.map(airport => airport.airportcity)
    const newCities = [...new Set(cities)]

    const searchBar = (e) => {
        setSearchValue(e.target.value)
    }

    const filterChange = (e) => {
        setFilterCity(e.target.value)
    }

    const filteredData = airportState.filter(item => {
        const searchMatch = item.airportshortname.toLowerCase().includes(searchValue.toLowerCase());
        const filterMatch = filterCity === '' || filterCity === item.airportcity
        return searchMatch && filterMatch
    })


 
  const closeDialogBox = () => {
    setIsDialogBoxOpen(false);
  }

  const deleteIt = (id) => {
    const updatedArray = airportState.filter(obj => obj.airportcode !== id)
  
    setAirportState(updatedArray)
    setIsDialogBoxOpen(false)
  }

  const onNew = () => {
    setOpenedEdit(true)
    setOpenCreate(true)
    setEditData({
        airportFormData
    })
  }
  const lotDataChange=(e)=>{
    const { name, value } = e.target;
    setActivelot({ ...activelot, [name]: value });
}



const LoadLot = (id) => {
   
   setLoading(true)
   setLoadMsg('Loading Parking Lot Details')

   fetch(`${Constants.apiUrl}/parkingadmin/parkinglots/${id}`,{
       method: 'GET',
       headers: {
           "Content-Type": "application/json",
           "Authorization": adminToken
       },
   })
   .then((resp) => resp.json())
   .then((data) => {
       // console.log(data);
       setLoading(false)
       console.log(data.pl);
       if(data.code==0){
       
           setActivelot(data.pl);
       }else if(data.code==-10){
           setIsExpired(true);
       }
       
       
   })
   .catch((error) => {
       console.error(error);
       setLoading(false)
       setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed});
   })
}

const onSaveParkinglot=(e)=>{
    e.preventDefault();
    const url= isNew ?`${Constants.apiUrl}/parkingadmin/parkinglot` : `${Constants.apiUrl}/parkingadmin/parkinglot/${activelot.parkinglotid}`;

   /*  if (!isFormValid()) {
        setIsInputEmpty(true) 
      } else {
        setIsInputEmpty(false)
    }
*/
    console.log(JSON.stringify(activelot));
    setLoading(true);
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': adminToken
        },
        body: JSON.stringify(activelot)
    })
    .then((resp) => resp.json())
    .then((data) => {
        setLoading(false);
        console.log(data);
        if(data.code==0){
              setOpenCreate(false); //close the dialog
            setAlert({open:true, title:'Parkinglot', subtitle:'Parkinglot Saved Successfully'});
          
        } 
        else if(data.code==-10){
            setIsExpired(true);
        }
        else{
            setAlert({open:true, title:'Parkinglot Issues', subtitle:data.status});
        }
    })
    .catch((error) => {
        console.log('**************************error *************************')
        console.log(error)
        setLoading(false)
         setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
    })
  }

  const airports = airportList;

useEffect(() => {
    if(parkinglotid==null){
        setView('new')
    }else{
        setView('details');
        LoadLot(parkinglotid);
    }
   
  }, [adminToken])

  return (
    <div className="admin-body">
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="manage-airport">
                <div className="head">
                    <h1>Airports</h1>
                    <p>Add New and Modify Old Airports</p>
                    <button>Details</button>
                    <button>Manage</button>
                    <button>Daily Lot</button>
                    <button>Holiday rate</button>
                    <button>Season rate</button>
                    <button>Block out date</button>


                </div>
                <div className="body-wrapper">
                    <div className="head-block">
                        <h3>Parkinglot: </h3>
                        <div className="head-icons">
                            <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                        </div>
                    </div>
                    <div className="adding-client">
                        <button onClick={() => onNew()}>Create New</button>
                        <div className="selection">
                            <label htmlFor="filter-lots">Select Airport City</label>
                            <select name="filterlots" id="filter-lots" value={filterCity} onChange={filterChange}>
                                <option value="">All Cities</option>
                                {
                                    newCities.map((city, index) => <option key={index} value={city}>{city}</option>)
                                }
                            </select>
                        </div>
                        <div className="search-input">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="text" placeholder='Search Airport by Name' value={searchValue} onChange={searchBar}/>
                        </div>
                    </div>

                    <div className="main-form">
                            <div className="head">
                                <h3>  {isNew ?'New':'Update' } Parking Lot</h3>
                                <FontAwesomeIcon icon={faClose} className='head-icon' onClick={() => setOpenCreate(false)}/>
                            </div>
                            <form method="post" onSubmit={onSaveParkinglot}>
                                <div className="parking-info">
                                        <h3>Parking Lot Information</h3>
                                        <div className="input">
                                            <label htmlFor="lot-name">Parking Lot Name</label>
                                            <input type="text" name='parkinglotname' id='lot-name' value={activelot.parkinglotname} required onChange={lotDataChange}/>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-id">Parking Lot ID</label>
                                                <input type="text" name='parkinglotid' id='lot-id' maxLength={15} value={activelot.parkinglotid} disabled={isNew ?false:true } required onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-type">Parking Lot Type</label>
                                                <input type="text" name='parkingtype' id='lot-type' required value={activelot.parkingtype} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-days">Minimum Days Allowed</label>
                                                <input type="number" min={1} name='minday' id='lot-days' value={activelot.minday} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-threshold">Low Inventory Threshold</label>
                                                <input type="text" name='lowinventory' id='lot-threshold' value={activelot.lowinventory} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-code">Three Digit Code</label>
                                                <input type="text" name='threedigitcode' id='lot-code' value={activelot.threedigitcode} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-size">Parking Lot Size</label>
                                                <input type="text" name='lotsize' required id='lot-size' value={activelot.lotsize} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-contactFirst">Contact First Name</label>
                                                <input type="text" name='contactfirstname'  id='lot-contactFirst' value={activelot.contactfirstname} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-contactLast">Contact Last Name</label>
                                                <input type="text" name='contactlastname' id='lot-contactLast' value={activelot.contactlastname} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lot-address">Address 1</label>
                                            <input type="text" name='address1' id='lot-address' required value={activelot.address1} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lot-addressTwo">Address 2</label>
                                            <input type="text" name='address2' id='lot-addressTwo' value={activelot.address2} onChange={lotDataChange}/>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-city">City</label>
                                                <input type="text" name='city' id='lot-city' required value={activelot.city} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-state">State</label>
                                                <select name="state" id="user-state" required value={activelot.state} onChange={lotDataChange}>
                                                    <option value="" disabled>Select State/Province</option>
                                                    {
                                                        states.map((state, id) => {
                                                        return (
                                                            <option key={id} value={state.code}>{state.name}</option>
                                                        )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-postcode">Postal Code</label>
                                                <input type="text" name='zipcode' id='lot-postcode' value={activelot.zipcode} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-phone">Phone</label>
                                                <input type="tel" name='phone' id='lot-phone' required value={activelot.phone} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-email">E-mail</label>
                                                <input type="email" name='email' id='lot-email' required value={activelot.email} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-fax">Fax</label>
                                                <input type="tel" name='fax' id='lot-fax' value={activelot.fax} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="short-statement">Short Security Statement</label>
                                            <ReactQuill
                                                id="short-statement"
                                                name='shortsecuritystatement'
                                                value={activelot.shortsecuritystatement?? ""}
                                                onChange={(e) => setActivelot({ ...activelot, shortsecuritystatement: e })}
                                            />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="full-statement">Full Security Statement</label>
                                            <p>{activelot.fullsecuritystatement}</p>
                                            <ReactQuill
                                                id="full-statement"
                                                name='fullsecuritystatement'
                                                value={activelot.fullsecuritystatement}
                                                onChange={(e) => setActivelot({ ...activelot, fullsecuritystatement: e })}
                                            />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lot-dist">Distance from Airport</label>
                                            <input type="text" name="distancestatement" id="lot-dist" value={activelot.distancestatement} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="transportation-statement">Transportation Statement</label>
                                            <p>{activelot.transportation}</p>
                                            <textarea value={activelot.transportation}></textarea>
                                            {/* <ReactQuill
                                                id="transportation-statement"
                                                name='transportation'
                                                value={activelot.transportation}
                                                onChange={(e) => setActivelot({ ...activelot, transportation: e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="arrival-info">Arrival Information</label>
                                            <p>{activelot.arrivalinfo}</p>
                                            <ReactQuill
                                                id="arrival-info"
                                                name='arrivalinfo'
                                                value={activelot.arrivalinfo}
                                                onChange={(e) => setActivelot({ ...activelot, arrivalinfo: e })}
                                            />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="parking-info">Parking Information</label>
                                            <p>{activelot.parkingrateinfo}</p>
                                            <ReactQuill
                                                id="parking-info"
                                                name='parkingrateinfo'
                                                value={activelot.parkingrateinfo}
                                                onChange={(e) => setActivelot({ ...activelot,parkingrateinfo : e })}
                                            />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="other-info">Other Information</label>
                                            <ReactQuill
                                                id="other-info"
                                                name='otherinfo'
                                                value={activelot.otherinfo}
                                                onChange={(e) => setActivelot({ ...activelot, otherinfo: e })}
                                            />
                                        </div>                                 
                                </div>
                                <div className="another-section">
                                    
                                        <h3>Another Section</h3>
                                       
                                       
                                        
                                        <div className="input">
                                            <label htmlFor="direction">Direction</label>
                                            
                                            <ReactQuill
                                                id="direction"
                                                name='direction'
                                                value={activelot.direction}
                                                onChange={(e) => setActivelot({ ...activelot, direction: e })}
                                            />
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-airports">Airport</label>
                                                <select name="airportcode" id="lot-airports" value={activelot.airportcode} onChange={lotDataChange}>
                                                    {
                                                        airports.map((airport, idx) => {
                                                            return (
                                                                <option key={idx} value={airport.airportcode}>{airport.airportcode} - {airport.airportshortname}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="pay-online">Full Payment Online</label>
                                                <select name="onlinepay" id="pay-online" value={activelot.onlinepay} onChange={lotDataChange}>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="daily-rate">Daily Rate</label>
                                                <input type="text" name='rate' id='daily-rate' value={activelot.rate} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="service-fee">Service Fee</label>
                                                <input type="text" name='servicefee' id='service-fee' required value={activelot.servicefee} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="access-fee">Access Fee</label>
                                                <input type="text" name='accessfee' id='access-fee' value={activelot.accessfee} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-rank">Rank</label>
                                                <input type="text" name='ranking' id='lot-rank' value={activelot.ranking} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="tax-rate">Tax Rate</label>
                                                <input type="text" name='taxrate' id='tax-rate' value={activelot.taxrate} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-status">Status</label>
                                                <select name="status" id="lot-status" value={activelot.status} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="receipt-barcode">Barcode on Receipt</label>
                                                <select name="generatebarcode" id="receipt-barcode" value={activelot.generatebarcode} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="barcode-type">Barcode Type</label>
                                                <select name="barcodetype" id="barcode-type" value={activelot.barcodetype} onChange={lotDataChange}>
                                                    <option value="" disabled>Select Barcode</option>
                                                    <option value="128">Code 128</option>
                                                    <option value="39">Code 39</option>
                                                    <option value="39">Code 39</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="barcode-type">QrCode on Receipt</label>
                                                <select name="isQrCode" id="isQrCode" value={activelot.isQrCode} onChange={lotDataChange}>
                                                    <option value="" disabled>Select Yes For QrCode</option>
                                                    <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                    
                                                   
                                                </select>
                                            </div>
                                          
                                        </div>
                                    
                                    
                                        <div className="head-input">
                                            <h2>NETPARK INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="location-code">Netpark Location Code</label>
                                                <input type="text" name='netparkCode' id='location-code' value={activelot.netparkCode} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="netpark-email">Should Netpark send E-mail?</label>
                                                <select name="netparkCode" id="netpark-email" value={activelot.netparkCode} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>PARKONECT INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="parkonect-id">Parkonect Garage ID</label>
                                                <input type="text" name='parkconectId' id='parkonect-id' value={activelot.parkconectId} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="same-daybooking">Allow same day booking?</label>
                                                <select name="samedaybooking" id="same-daybooking" value={activelot.samedaybooking} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>SPACE GENIUS INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="spacegenius-id">Space Genius Channel ID</label>
                                                <input type="text" name='spaceChannelid' id='spacegenius-id' value={activelot.spaceChannelid} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="spacegeniuscompany-id">Space Genius Company ID</label>
                                                <input type="text" name='spaceComId' id='spacegeniuscompany-id' value={activelot.spaceComId} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>AMINO INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="barcode-type">Parkinglot Support Amino?</label>
                                                <select name="isAmano" id="isAmano" value={activelot.isAmano} onChange={lotDataChange}>
                                                    <option value="" disabled>Select Yes For Amino</option>
                                                      <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                  
                                                   
                                                </select>
                                            </div>
                                            {(activelot.isAmano=='true' || activelot.isAmano) && <div className="input">
                                                <label htmlFor="Aminofacility">Amino Facility ID </label>
                                                <input type="text" name='aminoFacility' id='Aminofacility' required value={activelot.aminoFacility} onChange={lotDataChange}/>
                                            </div>} 
                                            
                                        </div>
                                    
                                </div>
                                <button className="savebtn" type='submit'>Save</button>
                            </form>
                        </div>
                   
                </div>
            </div>

            <div className={ isDialogBoxOpen ? "delete-wrapper show" : "delete-wrapper"}>
                <div className="dialog">
                    <div className="head">
                        <h4>Delete Airport</h4>
                        <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                    </div>
                <div className="main">
                    <h3>Are you sure you want to delete Airport: { editData.airportshortname }?</h3>
                    <p>This action will permanently delete this Airport. Please click 'Delete Airport' to continue or Cancel to abort!</p>
                    <div className="btns">
                        <button onClick={() => deleteIt(editData.airportcode)}>Delete Airport</button>
                        <p onClick={() => closeDialogBox()}>Cancel</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default SingleParkinglot