import React from 'react';
import '../../styles/User/articles.css'
import TopBar from '../../components/User/TopBar'
import { articles } from '../../data/articles';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function Articles({category}) {
    category = articles;

  return (
    <div className='articles'>
        <TopBar />
        <div className="body">
            <div className="heading">
                <h1>Airport Parking Inc Articles</h1>
                <p>We have many articles below if you are interested in learning more about Airport Parking INC</p>
            </div>
            <div className="articles-main">
                {
                    category.map( article => {
                        return(
                            <div className="article" key={article.id}>
                                <h3>{article.title}</h3>
                                <ul>
                                    {article.articles.map(item => {
                                        return (
                                            <li key={item.id}>
                                                <p>{item.name}</p>
                                                <Link to={`/articles/${article.title}/${item.id}`}><FontAwesomeIcon icon={faAngleRight} className='icon' /></Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default Articles